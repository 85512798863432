import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo1 from "../../Assets/logo1.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SearchModal from './SearchModal'
import { useTranslation } from "react-i18next"; // Import useTranslation
const Navbar = ({ onHomeClick, cartItemsCount  }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false); // State for search modal
  const { t } = useTranslation(); // Initialize translation function
  const { i18n } = useTranslation();
  const logoClasses = (() => {
    switch (i18n.language) {
      case 'fr':
        return "w-20 sm:w-40 sm:mr-[310px]  mr-[10px] sm:ml-[0px] ml-[60px] rotate-3 m-2"; // Styles for French
      case 'ar':
        return "w-20 sm:w-40 sm:mr-[10px]  mr-[40px] sm:ml-[70px] ml-[10px] rotate-3 m-2"; // Styles for Arabic
      default:
        return "w-20 sm:w-40 sm:mr-[210px]  mr-[10px] sm:ml-[10px] ml-[60px] rotate-3 m-2"; // Styles for English
    }
  })();
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const openSearchModal = () => {
    setIsSearchModalOpen(true);
  };

  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    axios
      .get('https://bk.idymorocco.com/api/category', {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        },
      })

      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  const isLoggedIn = !!localStorage.getItem("token");

  const handleCategoryClick = (categoryId, categoryName) => {
    navigate(`/products/${categoryId}`, { state: { categoryName } });
  };

  const handleSubcategoryClick = (categoryId, subcategoryId, categoryName, subcategoryName) => {
    navigate(`/products/${categoryId}/${subcategoryId}`, { state: { categoryName, subcategoryName } });
  };

  const handleSubSubcategoryClick = (categoryId, subcategoryId, subsubcategoryId, categoryName, subcategoryName, subSubcategoryName) => {
    navigate(`/products/${categoryId}/${subcategoryId}/${subsubcategoryId}`, { state: { categoryName, subcategoryName, subSubcategoryName } });
  };

  return (
    <div className={`w-full bg-white shadow-md ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
    dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
  {/* Main Navbar */}
  <div className="flex items-center justify-between px-4 sm:px-10 py-4">
    {/* Hamburger Icon (Mobile) */}
    <button
      className="text-black sm:hidden block focus:outline-none"
      onClick={toggleMenu}
    >
      <i className="fas fa-bars fa-2x"></i>
    </button>

    {/* Links (Left on Desktop) */}
    <ul className="hidden sm:flex space-x-8 text-lg font-hubballi">
      <li>
        <Link to="/" className="hover:text-[#ccb898] sm:ml-[20px] ml-[15px]" onClick={onHomeClick}>
          {t("nav_home")}
        </Link>
      </li>
      <li>
        <button
          onClick={toggleDropdown}
          className="hover:text-[#ccb898] "
        >
          {t("nav_shop")}
        </button>
      </li>
      <li>
        <Link to="/blog" className="hover:text-[#ccb898]">
          {t("nav_blog")}
        </Link>
      </li>
      <li>
        <Link to="/about-us" className="hover:text-[#ccb898]">
          {t("nav_about_us")}
        </Link>
      </li>
    </ul>

    {/* Logo (Center) */}
    <div className="flex justify-center flex-grow sm:flex-none">
      <Link to="/" onClick={onHomeClick}>
        <img
          src={logo1}
          alt="Idy Morocco Logo"
          className={logoClasses}
        />
      </Link>
    </div>

    {/* Icons (Right) */}
    <div className="flex items-center space-x-4 sm:space-x-8">
      <button onClick={openSearchModal} className="text-black">
        <i className="fas fa-search fa-xl sm:ml-[30px] ml-[15px]"></i>
      </button>
      <Link to="/cart" className="relative text-black">
        <i className="fas fa-shopping-bag fa-xl"></i>
        {cartItemsCount > 0 && (
          <span className="absolute -top-2 -right-2 bg-black text-white rounded-full h-5 w-5 flex items-center justify-center text-xs">
            {cartItemsCount}
          </span>
        )}
      </Link>
     {isLoggedIn ? (
            <Link to="/account" className="icon-link text-black">
              <i className="fas fa-user-cog fa-xl"></i>
            </Link>
          ) : (
            <Link to="/login" className="icon-link text-black">
              <i className="fas fa-user fa-xl"></i>
            </Link>
          )}
    </div>
  </div>

  {/* Mobile Links */}
  {menuOpen && (
    <ul className="sm:hidden flex flex-col items-center space-y-4 py-4 text-lg font-hubballi">
      <li>
        <Link to="/" className="hover:text-[#ccb898]" onClick={onHomeClick}>
          {t("nav_home")}
        </Link>
      </li>
      <li>
        <button onClick={toggleDropdown} className="hover:text-[#ccb898]">
          {t("nav_shop")}
        </button>
      </li>
      <li>
        <Link to="/blog" className="hover:text-[#ccb898]">
          {t("nav_blog")}
        </Link>
      </li>
      <li>
        <Link to="/about-us" className="hover:text-[#ccb898]">
          {t("nav_about_us")}
        </Link>
      </li>
    </ul>
  )}

  {/* Dropdown Categories */}
  {isDropdownOpen && (
    <div className="p-6 bg-white text-gray-800 font-hubballi grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 max-h-[70vh] overflow-y-auto">
      {categories.map((category) => (
        <ul key={category.id} className="mb-4">
          <li className="mb-2 text-sm uppercase">
            <button
              className="hover:text-[#ccb898]"
              onClick={() => handleCategoryClick(category.id, category.name)}
            >
              {t(category.name)}
            </button>
          </li>
          {category.sub_category?.map((sub) => (
            <li key={sub.id} className="ml-4 mb-2">
              <button
                className="hover:text-[#ccb898]"
                onClick={() =>
                  handleSubcategoryClick(
                    category.id,
                    sub.id,
                    category.name,
                    sub.name
                  )
                }
              >
                {t(sub.name)}
              </button>
              {sub.sub_sub_category?.map((subSub) => (
                <li key={subSub.id} className="ml-4">
                  <button
                    className="hover:text-[#ccb898]"
                    onClick={() =>
                      handleSubSubcategoryClick(
                        category.id,
                        sub.id,
                        subSub.id,
                        category.name,
                        sub.name,
                        subSub.name
                      )
                    }
                  >
                    {t(subSub.name)}
                  </button>
                </li>
              ))}
            </li>
          ))}
        </ul>
      ))}
      <ul>
        <li>
          <Link to="/all-products" className="hover:text-[#ccb898] uppercase">
            {t("ALL PRODUCTS")}
          </Link>
        </li>
      </ul>
    </div>
  )}

  <SearchModal isOpen={isSearchModalOpen} onClose={closeSearchModal} />
</div>

  );
};

export default Navbar;