import { useEffect, useState } from 'react';
import React from 'react';
import Navbar from "../../Components/Home/Navbar";
import "../../Styles/ProfilePage.css";
import { FaEye, FaEyeSlash,FaBars} from "react-icons/fa"; // Assuming you're using FontAwesome for the icons
import Loading from "../../Components/Home/Loading";
import axios from "axios";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { useTranslation } from "react-i18next"; // Import useTranslation
const SettingsPage = () => {
  const [error, setError] = useState("");
  const [user, setUser] = useState(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showInfo, setShowInfo] = useState(true);
  const [selectedSection, setSelectedSection] = useState("show-info");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const token = localStorage.getItem("token"); // Retrieve token from local storage
  const [userData, setUserData] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [orders, setOrders] = React.useState([]);
  const { t } = useTranslation(); // Initialize translation function
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);
  const [orderDetails, setOrderDetails] = useState(null); // Store single order details
  const [expandedOrder, setExpandedOrder] = useState(null); // Track expanded order
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('open'); // Default status
  const email = JSON.parse(localStorage.getItem('account_data')).email; // Get email from local storage
  const { i18n } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const ticketData = {
      subject,
      email,
      message,
    };
  
    try {
      const response = await axios.post(
        'https://bk.idymorocco.com/api/support',
        ticketData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            "Accept": "application/json",
          },
        }
      );
  
  
      // Show SweetAlert on success
      Swal.fire({
        icon: 'success',
        title: 'Ticket Sent!',
        text: 'Your support ticket has been sent successfully.',
        confirmButtonText: 'OK',
        customClass: {
          popup: 'my-custom-popup',
          title: 'my-custom-title',
          content: 'my-custom-content',
          confirmButton: 'my-custom-button',
        },
        background: '#f0f4f8', // Change background color
        timer: 5000, // Auto-close after 5 seconds (optional)
      });
  
      // Reset form fields
      setSubject('');
      setMessage('');
      setStatus('open'); // Reset status (if applicable)
    } catch (err) {
      console.error('Error creating ticket:', err);
  
      // Show SweetAlert for error
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Unable to create support ticket.',
        confirmButtonText: 'OK',
      });
  
      setError('Unable to create support ticket.');
    }
  };
  

  const fetchOrders = async () => {
    try {
      const response = await axios.get('https://bk.idymorocco.com/api/trackorders', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          "Accept": "application/json",
        },
      });
      setOrders(response.data); // Store orders in state
    } catch (err) {
      console.error('Error fetching orders:', err);
      setError('Unable to fetch orders.');
    }
  };
  
  
  
  // Fetch orders on component mount
  useEffect(() => {
    fetchOrders();
  }, []);
    // Toggle expanded order details
    const toggleOrderDetails = (id) => {
      setExpandedOrder(expandedOrder === id ? null : id); // Toggle visibility
    };

  const getStatusColor  = (status) => {
    switch (status) {
      case 'pending':
        return 'text-yellow-500';
      case 'processing':
        return 'text-blue-500';
      case 'shipped':
        return 'text-orange-500';
      case 'delivered':
        return 'text-green-500';
      case 'returned':
        return 'text-red-500';
      case 'canceled':
        return 'text-gray-500';
      default:
        return '';
    }
  };
// Call fetchOrders when the component mounts
useEffect(() => {
  fetchOrders();
}, []);
  useEffect(() => {
    const storedUserData = localStorage.getItem("account_data");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData)); // Parse and set user data
    }
    const userData = localStorage.getItem('account_data');

    if (userData) {
      try {
        const parsedUser = JSON.parse(userData);
    
        setUser(parsedUser);
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    } else {
      console.warn("No user data found in localStorage.");
    }
  }, []);

  const handleSectionClick = (section) => {
    setSelectedSection(section);
    setShowInfo(section === "show-info");
  };

  const handleLogout = () => {
    // Clear user data from local storage
    localStorage.removeItem('token');
    localStorage.removeItem('account_data');
  
    // Show SweetAlert2 toast for successful logout
    Swal.fire({
      toast: true,
      position: 'top-end', // This places it on the right side at the top
      icon: 'success',
      title: t("logout_success"),
      showConfirmButton: false,
      timer: 4000, // 3 seconds
      timerProgressBar: true,
    });
  
    // Redirect to login page or homepage after a slight delay
    setTimeout(() => {
      window.location.href = '/login'; // Change this to your desired route
    }, 1000); // Delay to ensure the toast message shows before the redirect
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    // Validate that newPassword and confirmPassword match
    if (newPassword !== confirmPassword) {
      alert("New password and confirm password do not match.");
      return;
    }

    // Ensure user data is available before proceeding
    if (!userData) {
      alert("No user data available.");
      return;
    }

    // Payload using values from local storage and form inputs
    const payload = {
      first_name: userData.first_name,
      last_name: userData.last_name,
      phone: userData.phone,
      address: userData.address,
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    };

    try {
      const response = await fetch("https://bk.idymorocco.com/api/change-information", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Pass token for authorization
          "Accept": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        alert("Password changed successfully!");
        // Optionally, reset form fields here
       
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        alert(`Error: ${data.message || "Password change failed"}`);
      }
    } catch (error) {
      console.error("Error changing password:", error);
      alert("An error occurred while changing the password.");
    }
  };

  if (!user) {
    return <Loading />
  }
  
  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  
  return (
    <>
  
      <Navbar />
      <div className={`mx-4 max-w-screen-xl min-h-screen sm:mx-8 xl:mx-auto overflow-y-auto ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
     dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} >
      <div className="grid grid-cols-1 sm:grid-cols-10  gap-4 pt-3 h-full">
        <div className="col-span-1 sm:col-span-2  sm:border-r-2 h-fit">
          <div className="sm:hidden mb-4 text-end">
            <button onClick={toggleMobileMenu} className="text-2xl">
              <FaBars />
            </button>
          </div>
          <ul className={`${showMobileMenu ? 'block' : 'hidden'} sm:block`}>
      {[
        { name: t('my_information'), key: 'show-info' },
        { name: t('change_password'), key: 'change-password' },
        { name: t('tracking'), key: 'tracking' },
        { name: t('support_tickets'), key: 'Support-Tickets' },
        { name: t('logout'), key: 'logout', onClick: handleLogout },
      ].map((item) => (
        <li
          key={item.key}
          className={`mt-5 cursor-pointer border-l-2 px-2 py-2 font-hubballi transition ${
            selectedSection === item.key
              ? 'border-l-[#ccb898] text-[#ccb898]'
              : 'border-transparent text-black'
          }`}
          onClick={() => (item.onClick ? item.onClick() : handleSectionClick(item.key))}
        >
          {item.name}
        </li>
      ))}
    </ul>
        </div>

        <div className="col-span-1 sm:col-span-8 rounded-xl border sm:px-8 h-full font-hubballi">
          <div className="pt-4">
            <h1 className="py-2 text-2xl font-hubballi">{t("My account")}</h1>
          </div>
          <hr className="mt-4 mb-8" />
          
          {selectedSection === "change-password" && (
            <form onSubmit={handlePasswordChange} className="space-y-6">
              <div className="relative">
                <label htmlFor="currentPassword" className="block text-sm text-gray-700 mb-2">
                  {t("Current Password *")}
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="currentPassword"
                  name="currentPassword"
                  className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none"
                  required
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <span
                  onClick={togglePasswordVisibility}
                  className="absolute right-3 bottom-4 cursor-pointer"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>

              <div className="relative">
                <label htmlFor="newPassword" className="block text-sm text-gray-700 mb-2">
                  {t("New Password *")}
                </label>
                <input
                  type={showNewPassword ? "text" : "password"}
                  id="newPassword"
                  name="newPassword"
                  className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none"
                  required
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                <span
                  onClick={toggleNewPasswordVisibility}
                  className="absolute right-3 bottom-4 cursor-pointer"
                >
                  {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>

              <div className="relative font-hubballi">
                <label htmlFor="confirmPassword" className="block text-sm text-gray-700 mb-2">
                  {t("Confirm New Password *")}
                </label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <span
                  onClick={toggleConfirmPasswordVisibility}
                  className="absolute right-3 bottom-4 cursor-pointer"
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>

              <div className="flex justify-between">
                <button type="submit" className="bg-black text-white p-3">
                  Apply Changes
                </button>
              </div>
            </form>
          )}

          {selectedSection === "show-info" && user && (
            <div className="pt-4">
              <div className="max-w-3xl mx-auto p-4 bg-white font-hubballi">
                <div className="flex flex-col sm:flex-row sm:space-x-6 ">
                  <div className="flex-1">
                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 mb-1">
                      {t("First Name")}
                    </label>
                    <div id="first-name">{user.first_name}</div>
                  </div>
                  <div className="flex-1">
                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700 mb-1">
                      {t("Last Name")}
                    </label>
                    <div id="last-name">{user.last_name}</div>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row sm:space-x-6 mt-4">
                  <div className="flex-1">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                      {t("Email")}
                    </label>
                    <div id="email">{user.email}</div>
                  </div>
                  <div className="flex-1">
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
                      {t("Phone")}
                    </label>
                    <div id="phone">{user.phone}</div>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row sm:space-x-6 mt-4">
                  <div className="flex-1">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">
                      {t("Address")}
                    </label>
                    <div id="address">{user.address}</div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedSection === "tracking" && (
            <div className="pt-4">
            <h1 className="text-2xl font-hubballi text-center">{t("Order Tracking")}</h1>
            <p className="mt-2 text-center">{t("Here you can track your orders and view their status.")}</p>
      
            <div className="container mx-auto px-4 md:px-8 lg:px-32 py-8">
              {error && <p className="text-red-500 text-center">{error}</p>}
      
              {orders.length > 0 ? (
                <div>
                  {orders.map((order) => (
                    <div key={order.id} className="border p-4 mb-4 rounded">
                      <div
                        className="flex justify-between items-center cursor-pointer"
                        onClick={() => toggleOrderDetails(order.id)} // Toggle order details
                      >
                        <p className="font-medium">
                         {order.Name}
                        </p>
                        <p className={`font-bold ${getStatusColor(order.status)}`}>
                          {order.status}
                        </p>
                      </div>
      
                      {/* Show details if this order is expanded */}
                      {expandedOrder === order.id && (
                        <div className="mt-4 p-4 bg-gray-50 rounded">
                          <p><strong>{t("Shipping Address:")}</strong> {order.Shipping_Address}</p>
                          <p><strong>{t("City2")}</strong> {order.city}</p>
                          <p><strong>{t("Total")}</strong> ${order.total}</p>
                          <p><strong>{t("Status")}</strong> {order.status}</p>
                          <p><strong>{t("updated At:")}</strong> {new Date(order.updated_at).toLocaleString()}</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <p className="mt-4 text-center bg-gray-500 text-white p-2 rounded">{t("No orders found.")}</p>
              )}
            </div>
          </div>
          
          )}
          {selectedSection === "Support-Tickets" && (
          <div className="container mx-auto p-6 ">
          <h1 className="text-3xl font-bold mb-4">{t("Support Tickets")}</h1>
          <form onSubmit={handleSubmit} className="space-y-4 mb-6">
            <div>
              <label className="block font-semibold">{t("Subject")}</label>
              <input
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="w-full p-2 border rounded"
                required
              />
            </div>
    
            <div>
              <label className="block font-semibold">{t("Message")}</label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full p-2 border rounded"
                rows="4"
                required
              />
            </div>
    
            <button type="submit" className="bg-black text-white  hover:bg-[#ccb898] px-4 py-2 ">
              {t("Submit Ticket")}
            </button>
          </form>
        </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default SettingsPage;