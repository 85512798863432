import React, { useState, useEffect } from 'react';
import Navbar from "../../Components/Home/Navbar";
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
    const { t, i18n } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent form refresh
    
        const contactData = { 
            name, 
            email, 
            message 
        };
    
        try {
            const response = await fetch("https://bk.idymorocco.com/api/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify(contactData),
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                console.error("Failed to send message:", errorData);
                alert(t('contact.error_message')); // Display translation-based error message
                return;
            }
    
            alert(t('contact.success_message')); // Show success alert
            setName(''); // Clear form fields
            setEmail('');
            setMessage('');
    
        } catch (error) {
            console.error('Error sending message:', error);
            alert(t('contact.error_message')); // Show error alert
        }
    };
    

    return (
        <>
            <Navbar />
            <div
                className={`container mx-auto p-6 font-hubballi ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
                dir={i18n.language === 'ar' ? 'rtl' : 'ltr'} // Set direction for this component
            >
                <h1 className="text-3xl font-bold mb-6 text-center">
                    {t('contact.title')}
                </h1>

                <div className="bg-white p-6">
                    <h2 className="text-2xl font-semibold mb-4">
                        {t('contact.contact_info')}
                    </h2>
                    <p className="mb-2">
                        <strong>{t('contact.address')}:</strong> Rue Mouslim, lot Boukar, Bab Doukala, Marrakech - Maroc
                    </p>
                    <p className="mb-2">
                        <strong>{t('contact.phone')}:</strong> +212 664 514 743
                    </p>
                    <p className="mb-4">
                        <strong>{t('contact.email')}:</strong>{' '}
                        <a href="mailto:Contact@idymorocco.com" className="text-[#ccb898] hover:underline">
                            Contact@idymorocco.com
                        </a>
                    </p>

                    <h2 className="text-xl font-semibold mb-4">
                        {t('contact.write_us')}
                    </h2>
                    <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder={t('contact.name_placeholder')}
                            className="w-full p-2 border border-gray-300 rounded-md"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <input
                            type="email"
                            placeholder={t('contact.email_placeholder')}
                            className="p-2 w-full border border-gray-300 rounded-md"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <textarea
                            placeholder={t('contact.message_placeholder')}
                            className="p-2 border border-gray-300 rounded-md"
                            rows="4"
                            required
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <button
                            type="submit"
                            className="bg-black text-white p-2 hover:bg-[#ccb898] transition duration-200"
                        >
                            {t('contact.send_button')}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ContactUs;
