import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import "../../Styles/Registerpage.css";
import Header from "../../Components/Home/Header";
import Navbar from "../../Components/Home/Navbar";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";

const RegisterPage = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const navigate = useNavigate();
  const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
  // Function to validate form inputs
  const validateForm = () => {
    if (!firstName || !lastName || !email || !phone || !address || !gender) {
      setErrorMessage("All fields are required.");
      return false;
    }
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      setErrorMessage("Please enter a valid email.");
      return false;
    }
    if (!passwordRegex.test(password)) {
      setErrorMessage(
        "Password must be at least 8 characters long, contain at least one uppercase letter and one number."
      );
      return false;
    }
    if (password !== passwordConfirmation) {
      setErrorMessage("Passwords do not match.");
      return false;
    }
    if (!/^\d+$/.test(phone)) {
      setErrorMessage("Phone number must contain only digits.");
      return false;
    }
    return true;
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await fetch("https://bk.idymorocco.com/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          password_confirmation: passwordConfirmation,
          phone: phone,
          address: address,
          gender: gender,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to register: ${errorData.error || "Unknown error"}`);
      }

      const data = await response.json();
      Swal.fire({
        title: 'Registration Successful!',
        text: 'Registration Successful!',
        icon: 'success',
        confirmButtonText: 'OK',
      });

      navigate("/login");
    } catch (error) {
      console.error("Registration failed", error);
      setErrorMessage(error.message || "Registration failed. Please try again.");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="register-container">
        <h1 className="register-title">{t("REGISTER")}</h1>
        <p className="register-subtitle">{t("Please fill in the information below:")}</p>
        <form className="register-form" onSubmit={handleRegister}>
          <input
            type="text"
            placeholder={t("First Name")}
            className="register-input"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder={t("Last Name")}
            className="register-input"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <select
            className="register-input"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            required
          >
            <option value="">{t("Select Gender")}</option>
            <option value="male">{t("Male")}</option>
            <option value="female">{t("Female")}</option>
          </select>
          <input
            type="email"
            placeholder={t("Email")}
            className="register-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder={t("Password")}
              className="register-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="password-toggle"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="password-container">
            <input
              type={showPasswordConfirmation ? "text" : "password"}
              placeholder={t("Confirm Password")}
              className="register-input"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              required
            />
            <button
              type="button"
              className="password-toggle"
              onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
            >
              {showPasswordConfirmation ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <input
            type="text"
            placeholder={t("Phone")}
            className="register-input"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder={t("Address")}
            className="register-input"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
          {errorMessage && <p className="font-hubballi error-message text-red-600">{errorMessage}</p>}
          <button type="submit" className="register-button">
            {t("CREATE MY ACCOUNT")}
          </button>
        </form>
        <p className="login-account">
          {t("Already have an account?")} <Link to="/login">{t("Login")}</Link>
        </p>
      </div>
    </div>
  );
};

export default RegisterPage;
