// Description.js

import React from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import "../../Styles/Description.css"; // Adjust the path as necessary

const Description = () => {
  const { t } = useTranslation(); // Initialize the translation function

  return (
    <div className="description-container">
      <p className="description-text">
        {t("description_text")} {/* Translated text */}
      </p>
    </div>
  );
};

export default Description;
