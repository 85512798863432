// src/Components/WhatsAppIcon.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "../../Styles/Whatsaap.css";

const WhatsAppIcon = () => {
  return (
    <a
      href="https://wa.me/+212616038393"
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-icon text-green-500 hover:text-black"
    >
      <FontAwesomeIcon icon={faWhatsapp} size="2x" />
    </a>
  );
};

export default WhatsAppIcon;
