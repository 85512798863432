import React, { useEffect, useState } from "react";
// import Header from "../../Components/Home/Header";
import Navbar from "../../Components/Home/Navbar";
import Sliders from "../../Components/Home/Sliders";
import Description from "../../Components/Home/Description";
import ProductCard  from "../../Components/Home/ProductCard";
import Elproduct  from "../../Components/Home/Elproduct";
import History  from "../../Components/Home/History";
import BlogHome  from "../../Components/Home/BolgHome";
import { useTranslation } from "react-i18next";
const HomePage = ({currency}) => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [items, setItems] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await fetch('https://bk.idymorocco.com/api/category'
                , {
                  method: 'GET', // Specify the method
                  headers: {
                    'Accept': 'application/json', // Specify content type if needed
                    
                  }
                }
              ); // Adjust the API endpoint as necessary
              const data = await response.json();
              if (data.length > 0) {
                  setCategoryName(data[0].name); // Assuming the first item has the desired name
                  setItems(data); // Assuming data is an array of items
              }
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };

      fetchData();
  }, []);
  useEffect(() => {
    // Trigger the animation when the component mounts
    setTriggerAnimation(true);
  }, []);

  return (
    <div>
    
      <Navbar />
      <Sliders triggerAnimation={triggerAnimation} />
      <Description />
      {/* <div>
            <h1 className="text-center text-3xl font-bold mb-8">{categoryName}</h1>
        </div> */}
        <h1 className="text-center text-3xl font-hubballi font-bold mb-8">{t("Home & Decor")}</h1>
      <ProductCard  currency={currency}/>
      <History/>
      <div className="">
      <h1 className="text-center text-3xl font-hubballi font-bold mb-8">{t("Élégance marocaine")}</h1>
      <Elproduct  currency={currency}/>
      </div>
      <BlogHome/>
    </div>
  );
};

export default HomePage;
