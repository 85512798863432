import React from 'react';

const NotFoundPage = () => {
  return (
    <section class="bg-white dark:bg-white font-hubballi">
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
            <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">404</h1>
            <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-black">Something's missing.</p>
            <p class="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
            <div onClick={() => window.location.href = `/`}>
            <a href="#" class=" bg-customBlack text-white hover:bg-white hover:text-black py-2 mt-6 px-6 rounded-md font-hubballi border border-gray-400">Back to Homepage</a>
            </div>
        </div>   
    </div>
</section>
  );
};

export default NotFoundPage;