
import React from "react";
import rugImage from './../../Assets/12edfe02-8a62-4f36-bbda-d1c33675bb3b.jpeg.jpg';
import "../../Styles/Description.css";
import "../../Styles/Blogpage.css"; // Adjust the path to your CSS file
import ahwach from "../../Assets/ahwach.png";
import product from "../../Assets/Product.png";
import kasbah from "../../Assets/kasbah.jpg";
import { useTranslation } from "react-i18next"; // Import hook for translation
import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
const BlogHome = () => {
  const { t } = useTranslation(); // Initialize translation hook
  const [email, setEmail] = useState('');

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        'https://bk.idymorocco.com/api/add-email',
        { Email: email },
        {
          headers: {
            'Accept': 'application/json',
          },
        }
      );

      // Display success message with SweetAlert2
      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: response.data.success,
        });
        setEmail(''); // Clear input after success
      }
    } catch (error) {
      // Display error message with SweetAlert2
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to subscribe. Please try again.',
      });
    }
  };
  return (
    
    <>
   
    <div className=" min-h-screen flex items-center justify-center m-10 ">
      <div className=" bloghome container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-14">
          <div className="relative">
            <img
              src={rugImage}
              alt="Moroccan lamps"
              className="w-[600px] h-auto" />
          </div>
          <div className="flex flex-col justify-center space-y-20">
          <h1 className="text-3xl m-4 text-center text-customBlack tracking-wider font-hubballi uppercase">
          {t("terroir_title")}
    </h1>
            <p className="text-customBlack text-2xl tracking-wider leading-relaxed leading-20	 font-hubballi">
            {t("terroir_paragraph")}
            </p>
            <div className="flex sm:justify-end justify-center mt-60">
              <button className="bg-black py-2 px-6 hover:bg-white hover:text-black text-white  border border-gray-400 rounded-md shadow font-hubballi">
              {t("shop_button")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h1 className=" text-center text-2xl mt-2">{t("artisans_journal_title")}
</h1>
<div className="blog-article-small-container relative max-w-4xl mx-auto p-4 flex flex-wrap gap-4">
  {/* Article 1 */}
  <div className="blog-article-small flex-1 min-w-full sm:min-w-[48%] lg:min-w-[30%] bg-white overflow-hidden">
    <img
      src={ahwach}
      alt="BERBER ‘AHWACH"
      className="blog-image-small w-full h-48 object-cover transition duration-300 ease-in-out hover:scale-110"
    />
    <div className="blog-article-content p-4">
      <h2 className="text-xl font-bold">{t("article_1_title")}</h2>
      <p className="mt-2 text-gray-600">
      {t("article_1_description")}
      </p>
      <a href="#read-more" className="read-more text-blue-500 mt-2 inline-block">
      {t("read_more")}
      </a>
    </div>
  </div>

  {/* Article 2 */}
  <div className="blog-article-small flex-1 min-w-full sm:min-w-[48%] lg:min-w-[30%] bg-white overflow-hidden ">
    <img
      src={product}
      alt="Crafts"
      className="blog-image-small w-full h-48 object-cover"
    />
    <div className="blog-article-content p-4">
      <h2 className="text-xl font-bold">{t("article_2_title")}</h2>
      <p className="mt-2 text-gray-600">
      {t("article_2_description")}
      </p>
      <a href="#read-more" className="read-more text-blue-500 mt-2 inline-block">
      {t("read_more")}
      </a>
    </div>
  </div>

  {/* Article 3 */}
  <div className="blog-article-small flex-1 min-w-full sm:min-w-[48%] lg:min-w-[30%] bg-white overflow-hidden ">
    <img
      src={kasbah}
      alt="Kasbah"
      className="blog-image-small w-full h-48 object-cover"
    />
    <div className="blog-article-content p-4">
      <h2 className="text-xl font-bold">{t("article_3_title")}</h2>
      <p className="mt-2 text-gray-600">
      {t("article_3_description")}
      </p>
      <a href="#read-more" className="read-more text-blue-500 mt-2 inline-block">
      {t("read_more")}
      </a>
    </div>
  </div>
</div>

      <div className="  flex items-center justify-center mt-8">  
      <div onClick={() => window.location.href = `blog`}>
        <button class=" bg-customBlack  text-white hover:bg-white hover:text-black py-2 mt-6 px-6 rounded-md font-hubballi border border-gray-400">
        {t("view_all_articles")}
        </button>
      </div>
      </div>
      <div className="bg-[#ccb898] py-8 flex justify-center items-center mx-4 sm:mx-8 mt-8">
      <div className="max-w-4xl text-center">
        <h2 className="text-2xl sm:text-3xl font-semibold mb-6 sm:mb-8 font-hubballi">
          {t('join_our_tribe_title')}
        </h2>
        <p className="text-lg sm:text-xl mb-4 sm:mb-6 font-hubballi text-customBlack tracking-wide sm:tracking-widest">
          {t('join_our_tribe_description')}
        </p>
        <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
          <input
            type="email"
            placeholder={t('subscribe')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="border bg-[#ccb898] border-black px-4 py-2 w-full max-w-xs sm:max-w-md placeholder-black font-hubballi focus:border-black focus:outline-none"
          />
          <button
            onClick={handleSubmit}
            className="text-white py-2 px-6 bg-black hover:bg-white hover:text-black border border-black font-hubballi uppercase sm:w-auto"
          >
            {t('subscribe')}
          </button>
        </div>
      </div>
    </div>


      </>
  );
};
export default BlogHome;