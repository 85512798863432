// History.js
import React from "react";
import rugImage from './../../Assets/rugs2.png';
import "../../Styles/Description.css"; // Adjust the path if needed
import { useTranslation } from "react-i18next"; // Import useTranslation
import { useNavigate } from "react-router-dom"; // For navigation

const History = () => {
  const { t } = useTranslation(); // Initialize the translation function
  const navigate = useNavigate(); // React Router's navigate hook

  return (
    <div className="history grid grid-cols-1 md:grid-cols-2 gap-8 items-center min-h-screen px-8 mt-10 mb-8">
      {/* Left Column: Text and Button */}
      <div className="space-y-14">
        {/* Title */}
        <h1 className="text-3xl m-4 text-center text-customBlack tracking-wider font-hubballi uppercase">
          {t("history_title")} {/* Translated title */}
        </h1>
        <p className="text-customBlack text-2xl tracking-widest leading-relaxed font-hubballi">
          {t("history_paragraph")} {/* Translated paragraph */}
        </p>

        {/* Read More Button */}
        <div className="flex sm:justify-start justify-center" onClick={() => navigate("/about-us")}>
          <button className="bg-customBlack text-white hover:bg-white hover:text-black py-2 mt-6 px-6 rounded-md font-hubballi border border-gray-400">
            {t("read_more")} {/* Translated button text */}
          </button>
        </div>
      </div>

      {/* Right Column: Image */}
      <div>
        <img
          src={rugImage}
          alt="Moroccan Rug"
          className="w-[600px] h-auto object-cover"
        />
      </div>
    </div>
  );
};

export default History;
