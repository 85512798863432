import React, { useState, useEffect } from 'react';
import "../../Styles/Header.css";
import ReactFlagsSelect from 'react-flags-select';
import { useTranslation } from 'react-i18next'; // Import useTranslation

const Header = ({ onCurrencyChange }) => {
  const { t, i18n } = useTranslation(); // Initialize translation
  const [selectedCountry, setSelectedCountry] = useState("MA"); // Default to "MA"
  const [language, setLanguage] = useState("US");

  const currencyMap = {
    MA: "MAD",
    US: "USD",
    EU: "EUR",
    GB: "GBP",
    AU: "AUD",
    CA: "CAD",
    AE: "AED",
    BH: "BHD",
    EG: "EGP",
    KW: "KWD",
    IL: "ILS",
    QA: "QAR",
    SA: "SAR",
    JP: "JPY",
    CN: "CNY",
  };

  // Automatically set to "MAD" if no currency is selected
  useEffect(() => {
    onCurrencyChange(currencyMap[selectedCountry]); // Call the function on mount with the default currency
  }, []); // Empty dependency array to run on mount only

  const handleCurrencyChange = (code) => {
    if (currencyMap[code]) {
      setSelectedCountry(code);
      onCurrencyChange(currencyMap[code]); // Call the function with the selected currency
    } else {
      // If no valid code is selected, default to MAD
      setSelectedCountry("MA");
      onCurrencyChange("MAD");
    }
  };

  const handleLanguageChange = (code) => {
    const lang = code === 'US' ? 'en' : code === 'FR' ? 'fr' : 'ar';
    i18n.changeLanguage(lang); // Change language dynamically
    setLanguage(code);
  };

  return (
    <div className="header-container">
      <div className="header-content">
        <h1 className="header-title">{t('header_title')}</h1> {/* Translated title */}
      </div>
      <div className="selectors-container">
        <div className="currency-selector">
          <ReactFlagsSelect
            countries={[
              "MA", "US", "FR", "EU", "GB", "AU", "CA", "AE", "BH", "EG",
              "KW", "IL", "QA", "SA", "JP", "CN"
            ]}
            customLabels={{
              MA: "MAD", FR: "EUR", US: "USD", EU: "EUR", GB: "GBP",
              AU: "AUD", CA: "CAD", AE: "AED", BH: "BHD", EG: "EGP",
              KW: "KWD", IL: "ILS", QA: "QAR", SA: "SAR", JP: "JPY", CN: "CNY"
            }}
            selected={selectedCountry}
            onSelect={handleCurrencyChange}
            placeholder="Select Currency"
            searchable
            searchPlaceholder="Search currencies"
          />
        </div>
        <div className="language-selector relative ">
      <select value={language} onChange={(e) => handleLanguageChange(e.target.value)} className='p-2.5 text-2xl' id='language'>
        <option className='text-2xl text-black' value="US">EN</option>
        <option className='text-2xl' value="FR">FR</option>
        <option className='text-2xl' value="SA">AR</option>
      </select>
    </div>
      </div>
    </div>
  );
};

export default Header;
