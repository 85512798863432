import React, { useEffect, useState } from "react";
import "../../Styles/Sliders.css";
import cover from "../../Assets/cover.png"; // Adjust the path to your image

const Sliders = ({ triggerAnimation }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (triggerAnimation) {
      setAnimate(false);
      setTimeout(() => {
        setAnimate(true);
      }, 10); // Small timeout to allow re-triggering animation
    }
  }, [triggerAnimation]);

  return (
    <div className="cover-container">
      <img
        src={cover}
        alt="Slider"
        className={`cover ${animate ? "fade-in" : ""}`}
      />
    </div>
  );
};

export default Sliders;
