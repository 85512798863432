import React from 'react'

function Loading() {
  return (
    <div class="flex justify-center items-center h-screen">
  <div class="rounded-full h-20 w-20 bg-orange-300 animate-ping"></div>
</div>
  )
}

export default Loading