import React from 'react';
import Navbar from "../../Components/Home/Navbar";

const TermsAndConditions = () => {
    return (
        <>
            <Navbar />
            <div className="container mx-auto p-20 font-hubballi">
                <h1 className="text-3xl font-bold mb-20 text-center">Conditions Générales d'Utilisation et de Vente</h1>
                <h2 className="text-2xl font-semibold mt-4 text-center">IDY MOROCCO</h2>

                <div className="mt-6">
                    <h3 className="text-xl font-semibold">Contenu</h3>
                    <ol className="list-decimal ml-6 mt-2">
                        <li>Introduction</li>
                        <li>Inscription et compte</li>
                        <li>Conditions générales de vente</li>
                        <li>Droit de rétractation</li>
                        <li>Retours et remboursements</li>
                        <li>Paiements</li>
                        <li>Règles concernant votre contenu</li>
                        <li>Nos droits d'utilisation de votre contenu</li>
                        <li>Utilisation du site Web</li>
                        <li>Propriété intellectuelle (Copyright) et marques déposées</li>
                        <li>Protection des données</li>
                        <li>Contrôle raisonnable et droits d'audit</li>
                        <li>Rôle de DYLA ART en tant que Marketplace</li>
                        <li>Limitations et exclusions de responsabilité</li>
                        <li>Indemnisation</li>
                        <li>Violation des présentes conditions générales</li>
                        <li>Intégralité de l'accord</li>
                        <li>Hiérarchie</li>
                        <li>Modification</li>
                        <li>Divisibilité</li>
                        <li>Cession</li>
                        <li>Droits des tiers</li>
                        <li>Loi applicable et juridiction</li>
                        <li>Les coordonnées de notre société</li>
                    </ol>
                </div>

                {/* Add each section below as needed */}
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">1. Introduction</h3>
                    <p className="mt-2 text-gray-700">
                    Dyla Art SARL (« Dyla Art » ou « nous ») exploite un site web de commerce électronique dite place de marché (« Marketplace »), La Marketplace est définie comme un site internet sur lequel des vendeurs indépendants, ont la possibilité de vendre leurs produits en ligne et les expédier moyennant, une commission prélevée par le site sur chaque vente. Notre Marketplace IDY MOROCCO sous le nom de domaine « www.idymorocco.com » permet la mise en relation de vendeurs soit toute personne morale qui vend un produit sur notre Marketplace (ci-après les « Vendeurs ») avec des acheteurs sur le site web soit toute personne physique ayant plus de 18 ans ou personne morale qui achète sur notre Marketplace ci-après les « Acheteurs »), et ce pour la vente et l'achat de divers produits (ci-après les « produits») les articles artisanaux et fait main soit tous les articles fabriqués et/ou conçus par le vendeur au Maroc («Territoire»).


Ces Conditions Générales s'appliquent aux acheteurs et aux vendeurs sur la Marketplace et régissent leur utilisation de la Marketplace et de tous les services associés.

En utilisant notre Marketplace, vous acceptez ces conditions générales dans leur intégralité. Si vous n'êtes pas d'accord avec ces conditions générales ou une partie de ces conditions générales, dans ce cas, vous ne devez pas utiliser notre Marketplace.


Si vous utilisez notre Marketplace dans le cadre d'une entreprise ou d'un quelconque autre projet commercial, vous :

Confirmez que vous avez obtenu l'autorisation nécessaire pour accepter les présentes conditions générales ;

Vous vous engagez, ainsi que la personne, la société ou toute autre entité juridique qui exploite cette entreprise ou ce projet commercial, à respecter les présentes conditions générales ;

Acceptez que le terme « vous », utilisé dans les présentes conditions générales fasse référence à la fois à l'utilisateur individuel et à la personne, à la société ou à l'entité juridique concernée, sauf indication contraire du contexte.

                    </p>
                </section>

                <section className="mt-6">
                    <h3 className="text-xl font-semibold">2. Inscription et compte</h3>
                    <p className="mt-2 text-gray-700">
                    Vous ne pouvez pas vendre ou acheter des produits sur notre Marketplace si vous avez moins de 18 ans (en utilisant notre Marketplace ou en acceptant les présentes conditions générales, vous nous garantissez et déclarez que vous avez au moins 18 ans).
Vous pouvez vous inscrire à un compte vendeur sur notre Marketplace en remplissant et en soumettant le formulaire d’inscription disponible sur notre Marketplace.

Vous déclarez et garantissez que toutes les informations fournies dans le formulaire d'inscription sont complètes et exactes.

                    </p>
                </section>

                <section className="mt-6">
                    <h3 className="text-xl font-semibold">3. Conditions Générales de Vente</h3>
                    <p className="mt-2 text-gray-700">
                    Vous reconnaissez et acceptez que :

Notre Marketplace offre un lieu de rencontre en ligne et de facilitation des transactions pour les vendeurs afin d’exposer, de vendre leurs produits sur notre Marketplace et les expédier, et pour les acheteurs afin d’acheter les produits proposés par les vendeurs ;

Nous acceptons et faisons-en sorte de faciliter les transactions et la finalisation des ventes sur notre Marketplace, pour le compte des vendeurs, toutefois, IDY MOROCCO n'est pas partie à la transaction entre le vendeur et l'acheteur. Par conséquence, un contrat de vente est formé uniquement entre l’acheteur et le vendeur et IDY MOROCCO n’assume aucune responsabilité découlant de ce contrat de vente ; Cependant, la Marketplace fera le suivi de toutes les transactions et réclamations volontairement dans le cadre d’une démarche de satisfaction client.

Un contrat pour la vente et l'achat d'un ou de plusieurs produits entrera en vigueur entre l’acheteur et le vendeur, et en conséquence vous vous engagez à acheter ou vendre le ou les produits concernés, dès la confirmation d'achat par l'acheteur via notre Marketplace.

Nous nous réservons le droit de refuser le service à quiconque pour quelque raison que ce soit et à tout moment.

L’acheteur s'engage à respecter l'ensemble des lois et règlements relatifs à l'acquisition de biens de toute nature.



Après avoir choisi son ou ses Produit(s), l'Acheteur devra vérifier le détail de sa commande, les coordonnées renseignées, son prix, renseigner si nécessaire, en fonction du mode de paiement choisi, les informations permettant le paiement du Produit. La confirmation de sa commande par le vendeur sera automatiquement bon pour acceptation de la part de l'Acheteur.

Les commandes effectuées par l'Acheteur sont indépendantes les unes des autres. Si une ou plusieurs commandes n'a ou n'ont pas fait l'objet d'acceptation par le(s) Vendeur(s) concerné(s), les autres commandes de l'Acheteur ne sont pas caduques et lient l'Acheteur. Par conséquent, le fait qu'un ou plusieurs Produits commandé(s) à un ou plusieurs Vendeurs sur la Plateforme ne soi(en)t pas disponible(s) n'est pas un motif de renonciation à la totalité́ des Produits commandés, l'Acheteur étant tenu par la commande du ou des autres Produit(s) disponible(s).

Sous réserve des présentes conditions générales, les conditions commerciales du vendeur régissent le contrat de vente et d'achat entre l'acheteur et le vendeur. Nonobstant ce qui précède, les dispositions suivantes seront incorporées dans le contrat de vente et d'achat entre l'acheteur et le vendeur :
Le prix de tout produit sera celui indiqué sur le site et le descriptif du produit concerné
;

Le prix de vente de tout produit doit inclure toutes les taxes et être conforme aux lois applicables en vigueur dans le Territoire ;
Les images et les couleurs affichées sur notre Marketplace sont les plus fidèles possible aux articles proposés. Nous ne pouvons pas garantir que l'affichage de n'importe quelle couleur sur votre écran d'ordinateur sera exact ;

Les produits concernés doivent être de bonne qualité, adaptés et sûrs pour tout usage spécifié dans le descriptif des produits et conformes à tous les aspects matériels, ainsi que toute autre description des produits fournie ou mis à disposition de l'acheteur par le vendeur ;
Nos transporteurs livrent pendant les heures normales de travail et peuvent exiger une signature à la réception, nous suggérons donc que votre commande soit livrée à une adresse où quelqu'un sera disponible pour l'accepter.

Le vendeur garantit qu’il détient un titre de propriété et est le seul propriétaire légal et bénéficiaire des produits qu’il vend et ces produits ne sont soumis à aucun droit des tiers ou restriction ou prohibition, y compris en ce qui concerne les droits de propriété intellectuelle et / ou toute enquête ou procédure pénale, d'insolvabilité ou fiscale.

                    </p>
                </section>

                {/* Continue with the other sections following the same pattern */}
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">4. Droit de rétractation</h3>
                    <p className="mt-2 text-gray-700">
                    Conformément aux dispositions de la loi 31-08 Le Client dispose d’un délai :
de quatorze (14) jours pour exercer son droit de rétractation ;

de trente (30) jours pour exercer son droit de rétractation, si le fournisseur n’honore pas son engagement de confirmer par écrit les informations prévues dans les articles 29 et 32 de la loi 31-08.
Et cela, sans avoir à se justifier, ni à payer de pénalités, à l’exception, le cas échéant, des frais de retour. Les délais mentionnés à l’alinéa précédent courent à compter de la date de réception du bien.
Lorsque le droit de rétractation est exercé, IDY MOROCCO représenté par Dyla art Sarl est tenu de rembourser, sans délai, au Client le montant payé et au plus tard dans les 15 jours suivant la date à laquelle ce droit a été exercé. Au-delà, la somme due est, de plein droit, productive d’intérêts au taux légal en vigueur.
Le droit de rétractation ne peut être exercé, sauf si les parties en sont convenues autrement, pour les contrats de fourniture de biens confectionnés selon les spécifications du client ou nettement personnalisés ou qui, du fait de leur nature, ne peuvent être réexpédiés ou sont susceptibles de se détériorer ou de se périmer rapidement conformément à l’article 38 de la loi 31-08.


                    </p>
                </section>
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">5. Retours et remboursements
</h3>
                    <p className="mt-2 text-gray-700">
                    L’acheteur a le droit de retourner un article, celui-ci doit être impérativement dans son emballage d’origine, emballé avec soin et ne doit être utilisé en aucune façon. L’article retourné doit être dans le même état dans lequel l’acheteur l’a reçu accompagné d’une preuve d’achat.

Certains articles sont non éligibles au retour, ce sont les articles soldés ou personnalisés ou périssables ou cosmétiques ou tout article qui, pour des raisons d'hygiène ou de protection de la santé, ne pourra être revendu sans risque pour le Vendeur.

Pour le retour d’articles endommagés ou non conformes à la commande, l’acheteur doit envoyer un mail à contact@idymorocco.com dans un délai de 14 jours à compter de la livraison. Passé ce délai, l’article est présumé conforme et en état d'usage. Le vendeur s’engage à l’échanger contre le même article, dans la limite du stock disponible ou la Marketplace procédera au remboursement. 
                    Nous ne remplaçons les produits que s’ils sont défectueux ou endommagés.

Toutefois, si le produit est perdu par le transporteur lors du retour du produit, l'Acheteur reste seul responsable et ne pourra prétendre à un quelconque remboursement du montant de l’article retourné.

Les retours de produits par les acheteurs et l'acceptation des produits retournés par les vendeurs seront gérés par nous conformément aux conditions définies à la politique des retours disponible sur notre Marketplace, qui peut être modifiée de temps à autre. L'acceptation des retours sera à notre discrétion, sous réserve du respect des lois applicables dans le Territoire.

Concernant les retours, les frais d’expédition ne sont pas remboursables et les frais d’expédition du retour seront à la charge de l’acheteur.

En cas de demande de remboursement, la Marketplace s’engage à informer par mail l’acheteur de l’acceptation ou refus de remboursement. Si acceptation du remboursement seul le montant de l’article vendu est remboursable, il sera crédité sur votre carte bancaire ou selon votre mode de paiement choisi pour le remboursement.
Les remboursements concernant les produits retournés seront gérés conformément aux conditions définies à la politique des retours disponible sur notre Marketplace, qui peut être modifiée de temps à autre. Nos règles sur les remboursements seront exercées à notre discrétion, sous réserve du respect des lois applicables dans le Territoire. Nous pouvons offrir des remboursements, à notre discrétion.


Les produits retournés seront acceptés et les remboursements seront effectués par IDY MOROCCO, pour et au nom du vendeur.

Les modifications apportées concernant la politique des retours, seront effectives et s'appliqueront à tous les achats effectués à partir de la date de publication de ladite modification sur notre site Web.


                    </p>
                </section>
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">6.Paiements</h3>
                    <p className="mt-2 text-gray-700">
                    Le vendeur s’engage à prendre en charge les frais de la solution de paiement choisie par le client que ce soit en carte bancaire marocaine ou étrangère, ainsi que les frais du virement bancaire adressé au vendeur lors du paiement en vertu des présentes conditions générales conformément aux Informations et Directives de notre Marketplace.
                    </p>
                </section>
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">7. Règles concernant votre contenu</h3>
                    <p className="mt-2 text-gray-700">
                    Dans les présentes conditions générales, "votre contenu" signifie :

Tous les éléments, travaux et matériaux (y compris, sans s'y limiter, les textes, les graphiques, les images, le matériel audio, le matériel vidéo, le matériel audiovisuel, les scripts, les logiciels et les fichiers) que vous nous soumettez à nous ou à notre Marketplace pour stockage ou publication, traitement ou transmission ultérieure ;

Toutes les communications sur notre Marketplace, y compris les avis sur les produits, les remarques et les commentaires.

Votre contenu et l'utilisation de votre contenu par nous, conformément aux présentes conditions générales, doit être exact, complet et authentique.
Votre contenu doit être approprié, civil et de bon goût, et être conforme aux normes d'éthique et de comportement généralement acceptés sur Internet, et ne doit pas :

Être offensant, obscène, indécent, pornographique, suggestif ou sexuellement explicite ;

Dépeindre la violence de manière explicite, graphique ou gratuite ;

Être blasphématoire, en violation de la législation sur la haine raciale ou religieuse ou la discrimination ;


Être trompeur, frauduleux, menaçant, abusif, harcelant, antisocial, haineux, discriminatoire ou incendiaire ;

Causer de la gêne, des désagréments ou une anxiété inutile à quiconque ; ou constitue un spam.

Votre contenu ne doit pas être illégal ou illicite, porter atteinte aux droits légaux de toute personne ou être susceptible de donner lieu à des poursuites judiciaires contre toute personne (dans chaque cas dans n'importe quelle juridiction et en vertu de toute loi applicable). Votre contenu ne doit pas enfreindre ni violer :

Tout droit d'auteur, droit moral, droit de base de données, droit de marque, droit de conception de dessins et modèles, droit de substitution ou tout autre droit de propriété intellectuelle ;

Tout droit de confidentialité, droit à la vie privée ou droit en vertu de la législation sur la protection des données ;

Toute obligation contractuelle due à toute personne ; ou toute ordonnance du tribunal et décision judiciaire.

Vous ne devez pas utiliser notre Marketplace pour créer un lien vers un site Web ou une page Web consistant en ou contenant un élément ou contenu qui, s'il était affiché sur notre Marketplace, violerait les dispositions des présentes conditions générales.

Vous ne devez pas soumettre à notre Marketplace tout matériel ou contenu qui fait ou a déjà fait l'objet de menaces ou de réelles poursuites judiciaires ou de toute autre plainte similaire.






La fonction qui permet aux utilisateurs sur notre Marketplace de donner leur avis et commentaires peut être utilisée pour faciliter les évaluations des acheteurs sur les produits. Vous ne devez pas utiliser ladite fonction ou toute autre forme de communication pour fournir des critiques et/ou des
avis inexacts, inauthentiques ou faux.

Vous ne devez pas interférer avec une transaction en : (i) contactant un autre utilisateur pour acheter ou vendre un article listé sur notre Marketplace en dehors de notre Marketplace ; ou (ii) communiquer avec un utilisateur impliqué dans une transaction active ou terminée pour l'avertir d’éviter un acheteur, un vendeur ou un article particulier ; ou (iii) contacter un autre utilisateur avec l'intention de collecter des paiements.

Vous reconnaissez que tous les utilisateurs de notre Marketplace sont seuls responsables des interactions avec les autres utilisateurs et vous devez faire preuve de prudence et de bon jugement dans votre communication avec les utilisateurs. 

Si vous avez connaissance de tout élément, contenu ou activité illégale et illicite sur notre Marketplace, ou de tout élément, contenu ou activité qui enfreint les présentes conditions générales, vous pouvez nous en informer en nous contactant aux coordonnées définies à la section 24 ci- dessous.


                    </p>
                </section>
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">8. Nos droits d’utilisation de votre contenu</h3>
                    <p className="mt-2 text-gray-700">
                    Vous nous accordez une licence mondiale, irrévocable, non exclusive et libre des redevances des droits d'utilisation, de reproduction, de stockage, d'adaptation, de publication, de traduction et de distribution de votre contenu sur nos canaux de commercialisation et marketing et tout autre support existant ou futur.

Vous nous accordez le droit de sous-licencier les droits concédés en vertu de l'article 8.1.

Vous nous accordez le droit d'intenter une action pour violation des droits concédés en vertu de l'article 8.1.

Vous renoncez par la présente à tous vos droits moraux sur votre contenu dans la limite autorisée par la loi applicable ; et vous garantissez et déclarez que tous les autres droits moraux sur votre contenu ont fait l’objet d’une renonciation dans la mesure maximale autorisée par la loi applicable.

Sans préjudice de nos autres droits en vertu des présentes conditions générales, si vous enfreignez nos règles sur le contenu de quelque manière que ce soit, ou si nous soupçonnons raisonnablement que vous avez enfreint nos règles sur le contenu, nous pouvons supprimer, annuler la publication ou modifier tout ou partie de votre contenu.



                    </p>
                </section>
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">9. Utilisation du site Web</h3>
                    <p className="mt-2 text-gray-700">
                    Dans cette section 9, les mots « Marketplace » et « site Web » sont utilisés de manière interchangeable pour désigner le site Web de IDY MOROCCO sous le nom de domaine www.idymorocco.com .
Vous pouvez :
Afficher les pages de notre site Web dans un navigateur Web ;

Télécharger des pages de notre site Web pour la mise en cache dans un navigateur Web ;

Imprimer des pages de notre site Web pour votre usage personnel et non commercial, à condition que cette impression ne soit pas systématique ou excessive

Diffuser des fichiers audio et vidéo à partir de notre site Web à l'aide du lecteur multimédia de notre site Web ;

Utiliser nos services de Marketplace au moyen d’un navigateur Web, sous réserve du respect les autres dispositions des présentes conditions générales.

Sauf dans les cas expressément autorisés par la section 9.2 ou les autres dispositions des présentes conditions générales, vous ne devez télécharger aucun élément de notre site Web ni enregistrer un tel élément sur votre ordinateur.


Vous ne pouvez utiliser notre site Web qu'à des fins personnelles et professionnelles pour vendre ou acheter des produits sur notre Marketplace.

Sauf dans les cas expressément autorisés par les présentes conditions générales, vous ne devez pas éditer ou modifier, de quelque manière que ce soit, tout élément ou contenu sur notre site Web.

À moins que vous ne possédiez ou ne contrôliez des droits réels et pertinents sur le matériel ou le contenu en question, vous ne devez pas :

Republier le contenu ou le matériel de notre site Web (y compris la republication sur un autre site Web) ;

Vendre, louer ou concéder en sous-licence du contenu ou du matériel de notre site Web ;

Montrer tout matériel ou contenu de notre site Web en public ;

Exploiter le matériel ou contenu de notre site Web à des fins commerciales ;

Redistribuer le matériel ou le contenu de notre site Web.

Nonobstant le paragraphe 7, vous pouvez transmettre des liens vers des produits sur notre site Web et redistribuer notre bulletin d'information (newsletter) et notre matériel promotionnel sous forme imprimée et électronique à toute personne.

Nous nous réservons le droit de suspendre ou de restreindre l'accès à notre site Web, à certaines parties de notre site Web et / ou aux fonctionnalités de notre site Web. Nous pouvons, par exemple, suspendre l'accès au site Web pendant la maintenance du serveur ou lorsque nous mettons à jour le site Web. Vous ne devez pas contourner, ou tenter de contourner, toute mesure de restriction d'accès sur le site Web.

Vous ne devez pas :

Utiliser notre site Web de quelque manière que ce soit où prendre des mesures qui causent ou peuvent causer des dommages au site Web ou une altération des performances, de la disponibilité, de l'accessibilité, de l'intégrité ou de la sécurité du site Web ;

Utiliser notre site Web d'une manière contraire à l'éthique, illégale, illicite, frauduleuse ou nuisible, ou en relation avec un but ou une activité illégale, illicite, frauduleuse ou nuisible ;

Pirater ou altérer par tout autre moyen notre site Web ;

Sonder, scanner ou tester la vulnérabilité de notre site Web sans notre permission ;

Contourner tout système ou processus d'authentification ou de sécurité sur notre site Web ou en relation avec ce dernier ;

Utiliser notre site Web pour copier, stocker, héberger, transmettre, envoyer, utiliser, publier ou distribuer tout matériel ou contenu constitué de (ou lié à) tout logiciel espion, virus informatique, cheval de Troie, ver, enregistreur de frappe, rootkit ou tout autre logiciel informatique malveillant ;

Imposer une charge déraisonnablement élevée sur les ressources de notre site Web (y compris la bande passante, la capacité de stockage et la capacité de traitement) ;


Déchiffrer ou décrypter toute communication envoyée par ou vers notre site Web sans notre permission ;

Mener des activités de collecte de données systématiques ou automatisées (y compris, sans limitation, le grattage, l'exploration de données, l'extraction de données et la collecte de données) sur ou en relation avec notre site Web sans notre consentement écrit exprès ;


Accéder à notre site Web ou interagir autrement avec lui à l'aide de tout robot, araignée ou tout autre moyen automatisé, sauf à des fins d'indexation des moteurs de recherche ;

Utiliser notre site Web sauf au moyen de nos interfaces publiques ;

Utiliser les données collectées sur notre site Web pour toute activité de marketing direct (y compris, sans limitation, le marketing par courrier électronique, le marketing par SMS, le télémarketing et le publipostage) ;

Faire tout ce qui interfère avec l'utilisation normale de notre site Web ;

En cas d’absence du vendeur ou d’impossibilité à honorer les commandes, le vendeur s'engage à en informer IDY MOROCCO par mail en précisant la durée de son absence.

Le Vendeur dont le comportement (sur la Plateforme ou hors Plateforme) est susceptible de nuire à l'image et/ou à la réputation de Dyla Art SARL ou de la Plateforme www.idymorocco.com pourra voir son comportement sanctionné ou exclu du site web par IDY MOROCCO conformément aux présentes Conditions Générales, notamment en cas d'évaluations négatives répétées ou de litiges consécutifs avec un ou plusieurs Acheteurs.

Le Vendeur doit au préalable fournir ses coordonnées bancaires (RIB) à IDY MOROCCO. Le Vendeur accepte que les montants qui lui sont dus au titre de Produits vendus sur la Plateforme www.idymorocco.com et qui sont réglés sur la Plateforme www.idymorocco.com par carte bancaire soient crédités sur son compte bancaire.

Le Vendeur a l'obligation de régler tout litige en coopérant et en agissant de bonne foi envers IDY MOROCCO et les acheteurs. Le litige doit être réglé de façon professionnelle et aimable et à l'avantage de l'Acheteur autant que possible, même si ce dernier a une attitude désagréable. Le Vendeur est alors libre de mettre fin à la transaction en remboursant l'acheteur tout en restant courtois.

Les prix des produits affichés sur notre Marketplace www.idymorocco.com doivent inclure notre commission à partir de 15%. Et le montant des frais d’expédition s’affichera avant le paiement.



                    </p>
                </section>
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">10. Propriété intellectuelle (Copyright) et marques déposées</h3>
                    <p className="mt-2 text-gray-700">
                    Sous réserve des dispositions expresses des présentes conditions générales :

Nous, avec nos concédants de licence, détenons et contrôlons tous les droits d'auteur et autres droits de propriété intellectuelle sur notre site Web ainsi que sur le matériel et tout contenu sur notre site Web ;

Tous les droits d'auteur et autres droits de propriété intellectuelle sur notre site Web ainsi que le matériel et contenu sur notre site Web sont réservés.

Les logos de IDY MOROCCO et nos autres marques déposées et non déposées sont des marques qui nous appartiennent ; nous n'accordons aucune autorisation pour l'utilisation de ces marques, et une telle utilisation peut constituer une violation de nos droits.


Les marques commerciales ou marques de service enregistrées et non enregistrées par des tiers sur notre site Web appartiennent à leurs propriétaires respectifs et nous n'endossons pas et nous ne sommes affiliés à aucun des titulaires de ces droits et, en tant que tels, nous ne pouvons accorder aucune licence pour exercer de tels droits.


                    </p>
                </section>
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">11. Protection des données</h3>
                    <p className="mt-2 text-gray-700">
                    Les acheteurs acceptent le traitement de leurs données personnelles conformément à la législation en vigueur et aux usages éthiques, IDY MOROCCO s’engage à assurer la protection, la confidentialité, et la sécurité des données personnelles.

IDY MOROCCO traitera toutes les données personnelles obtenues via la Marketplace www.idymorocco.com et les services associés conformément à la législation en vigueur et aux usages éthiques.

Contrôle raisonnable et droits d'audit

Nous avons mis en place un programme de conformité de lutte contre le blanchiment d'argent et nous nous réservons le droit d'effectuer des vérifications de diligence raisonnable sur tous les utilisateurs de notre Marketplace.

Vous vous engagez à nous fournir toutes les informations, documents dont nous pouvons avoir besoin
:

Afin de vérifier votre adhésion et l'exécution de vos obligations en vertu du présent Accord
;

Aux fins de divulgation, en vertu d’une ordonnance valide d'un tribunal ou d'un autre organisme gouvernemental ;

Dans la mesure où la loi ou la réglementation applicable l’exige.


                    </p>
                </section>
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">12. Le rôle de www.idymorocco.com en tant que Marketplace</h3>
                    <p className="mt-2 text-gray-700">
                    Vous reconnaissez que :

Nous ne confirmons pas l'identité de tous les utilisateurs de notre Marketplace, nous ne vérifions pas leur solvabilité ou leur bonne foi, et dans tous les cas, nous ne les contrôlons pas ;

 
Nous ne sommes parties à aucun contrat de vente ou d'achat de produits listés sur la Marketplace, dans la mesure où notre rôle est de faciliter les transactions réalisées entre les acheteurs et les vendeurs. Par conséquent, nous n’assumons aucune responsabilité ayant pour origine un tel contrat de vente et nous ne sommes ni l’agent ni le mandataire des vendeurs et ces derniers sont responsables de leurs produits ;

Nous ne sommes impliqués dans aucune transaction entre un acheteur et un vendeur de quelque manière que ce soit, sauf que nous facilitons les transactions sur la Marketplace pour les acheteurs et les vendeurs, assurons l’expédition et traitons les paiements néanmoins nous suivrons volontairement les réclamations.

Nous ne sommes pas les agents ou mandataires des acheteurs ou des vendeurs, et en conséquence, nous ne serons pas responsables envers quiconque en ce qui concerne l'offre de vente, la vente ou l'achat de tout produit annoncé et listé sur notre Marketplace
; en outre, nous ne sommes pas responsables de l'exécution des obligations contractuelles découlant d'un contrat de vente ou d'achat de tout produit et nous n'aurons aucune obligation de médiation entre les parties à un tel contrat. Mais nous veillerons au bon déroulement des transactions dans le cadre d’une démarche de satisfaction client.

Nous ne garantissons ni ne représentons :

L’exhaustivité ou l'exactitude des informations publiées sur notre Marketplace ;

Que toute information, élément ou contenu publié sur notre Marketplace est à jour ;

Que notre Marketplace fonctionnera sans faute ou erreur en tout temps ;

Que notre Marketplace ou tout service sur notre Marketplace restera disponible en tout temps.

Nous nous réservons le droit d'interrompre, de discontinuer ou de modifier tout ou partie de nos services disponibles sur notre Marketplace, et de cesser de publier sur notre Marketplace, à tout moment à notre seule discrétion, sans préavis ni explication ; et vous n'aurez droit à aucune compensation ou autre paiement en cas d'interruption, de discontinuité, de modification ou d’altération des services sur la Marketplace, ou si nous cessons de publier la Marketplace.

Nous ne garantissons aucun résultat commercial concernant l'utilisation de notre Marketplace.


Dans la mesure maximale autorisée par la loi applicable et sous réserve de la section 13.1 ci-dessous, nous excluons toutes les déclarations et garanties relatives à l'objet des présentes conditions générales, de notre Marketplace et de l'utilisation de notre Marketplace.


                    </p>
                </section>
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">13. Limitations et exclusions de responsabilité</h3>
                    <p className="mt-2 text-gray-700">
                    Rien dans les présentes conditions générales ne viendra :

Limiter toute responsabilité d'une manière non autorisée par la loi applicable ;

Exclure toute responsabilité ou tout droit légal qui ne peut être exclu en vertu de la loi applicable.

Les limitations et exclusions de responsabilité énoncées dans la présente section 13 et ailleurs dans les



présentes conditions générales :

Sont soumises aux dispositions de la section 14.1; et

Régissent toutes les responsabilités découlant des présentes conditions générales ou liées à l'objet des présentes conditions générales, y compris les responsabilités contractuelles, responsabilité délictuelle (y compris la négligence) et pour manquement à une obligation légale, sauf dans la mesure où les présentes conditions générales en disposent expressément autrement.

En ce qui concerne les services qui vous sont offerts gratuitement, nous ne serons pas responsables envers vous de toute perte ou dommage de quelque nature que ce soit.

Pour les commandes hors du Maroc, l'acheteur peut être soumis à des droits d'importation et frais de douane supplémentaires. Ceux-ci sont perçus au port d'entrée et sont à la charge de l'acheteur.

Notre responsabilité globale envers vous en ce qui concerne tout contrat visant à vous fournir des services en vertu des présentes conditions générales, ne doit pas dépasser le montant de l’article vendu et ses frais d’expédition en vertu dudit contrat. Chaque transaction distincte sur notre Marketplace constitue un contrat distinct aux fins de la présente section 14.4.

Nonobstant la section 14.4 ci-dessus, nous ne serons pas responsables envers vous de toute perte ou dommage de quelque nature que ce soit, y compris en ce qui concerne :

Toute perte occasionnée par une interruption ou un dysfonctionnement du site Web ;

Toute perte découlant d'un événement ou de plusieurs événements hors de notre contrôle raisonnable ;

Toute perte commerciale, y compris (sans limitation) la perte des bénéfices ou dommages sur les profits, des revenus, des recettes de l'utilisation, de la production, des économies anticipées, des affaires, des contrats, des opportunités commerciales ou des fonds de commerce ;
Toute perte ou corruption de toute donnée, base de données ou logiciel ;
Toute perte ou dommage spécial, indirect ou consécutif.

Nous acceptons que nous avons un intérêt à limiter la responsabilité personnelle de nos dirigeants et employés et, eu égard à cet intérêt, vous reconnaissez que nous sommes une entité à responsabilité limitée; vous acceptez de ne pas porter plainte personnellement contre nos dirigeants ou employés pour toute perte que vous subissez en rapport avec l’utilisation de notre Marketplace ou les présentes conditions générales (cela ne limitera ni n'exclura la responsabilité de l'entité à responsabilité limitée elle- même pour le actes et omissions de nos dirigeants et employés).

Notre Marketplace comprend des liens hypertexte vers d'autres sites Web détenus et exploités par des tiers ; ces liens hypertexte ne sont pas des recommandations. Nous n'avons aucun contrôle sur les sites Web tiers et leur contenu, et nous déclinons toute responsabilité à leur égard ou pour toute perte ou dommage pouvant résulter de leur utilisation.


                    </p>
                </section>
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">14. Indemnisation</h3>
                    <p className="mt-2 text-gray-700">
                    Par la présente, vous nous indemnisez et vous vous engagez à nous tenir indemnisés contre :

Toutes les pertes, dommages, coûts, responsabilités et dépenses (y compris, sans limitation, les frais de justice et tous les montants payés par nous à un tiers en règlement d'une réclamation ou d'un litige) encourus ou subis par nous et résultant directement ou indirectement de votre utilisation de notre Marketplace ou de toute violation par vous de toute disposition des présentes conditions générales ou des codes, politiques ou directives de IDY MOROCCO; et

Toute responsabilité en matière de TVA ou autre responsabilité en matière fiscale que nous pourrions encourir en relation avec toute vente, fourniture ou achat effectué via notre Marketplace, lorsque cette responsabilité découle de votre défaut de paiement, de retenue, de déclaration ou d'enregistrement pour le paiement de toute TVA ou autre taxe dûment due dans une quelconque juridiction.


                    </p>
                </section>
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">15. Violation des présentes conditions générales</h3>
                    <p className="mt-2 text-gray-700">
                    Si nous autorisons la création et l'enregistrement d'un compte vendeur sur notre Marketplace, celui-ci restera ouvert indéfiniment, sous réserve des présentes conditions générales.

Si vous enfreignez les présentes conditions générales ou si nous soupçonnons raisonnablement que vous avez enfreint les présentes conditions générales ou tout code, politique ou directive de IDY MOROCCO de quelque manière que ce soit, nous nous réservons le droit de :

Suspendre temporairement votre accès à notre Marketplace ;

Vous interdire définitivement l'accès à notre Marketplace ;

Empêcher les ordinateurs utilisant votre adresse IP d'accéder à notre Marketplace ;

Contacter l’un ou l’ensemble de vos fournisseurs de services Internet et leur demander de bloquer votre accès à notre Marketplace ;

Suspendre ou supprimer votre compte sur notre Marketplace ; et / ou

Intenter une action en justice contre vous, que ce soit pour rupture de contrat ou autre.

Lorsque nous suspendons, interdisons ou bloquons votre accès à notre Marketplace ou à une partie de notre Marketplace :

Vous ne devez prendre aucune mesure pour contourner une telle suspension, interdiction ou blocage (y compris, sans limitation, la création et / ou l'utilisation d'un compte différent).


                    </p>
                </section>
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">16. Intégralité de l’accord
</h3>
                    <p className="mt-2 text-gray-700">
                    Les présentes conditions générales, et les codes, politiques et directives de IDY MOROCCO accessibles sur notre Marketplace www.idymorroco.com( et en ce qui concerne les vendeurs : les conditions générales applicables au vendeur) constituent l'intégralité de l'accord entre vous et nous en ce qui concerne votre utilisation de notre Marketplace et remplacent tous les accords précédents entre vous et nous en ce qui concerne votre utilisation de notre Marketplace.


                    </p>
                </section><section className="mt-6">
                    <h3 className="text-xl font-semibold">17. Hiérarchie</h3>
                    <p className="mt-2 text-gray-700">
                    En cas de conflit entre les présentes conditions générales d’utilisation et de vente IDY MOROCCO et les codes, politiques et directives de IDY MOROCCO, les présentes conditions générales, et les codes, politiques et directives de IDY MOROCCO prévaudront dans l'ordre indiqué ici.


                    </p>
                </section>

                <section className="mt-6">
                    <h3 className="text-xl font-semibold">18. Modification</h3>
                    <p className="mt-2 text-gray-700">
                    Nous pouvons réviser les présentes conditions générales d’utilisation et de vente, et les codes, politiques et directives de IDY MOROCCO de temps à autre sans préavis.

Les conditions générales révisées s'appliquent à compter de la date de leur publication sur notre Marketplace.


                    </p>
                </section>
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">19. Divisibilité</h3>
                    <p className="mt-2 text-gray-700">
                    
Si une disposition des présentes conditions générales est jugée par un tribunal ou une autre autorité



compétente comme étant illégale et/ou inapplicable, les autres dispositions resteront en vigueur.
Si une disposition illégale et/ou inapplicable des présentes conditions générales deviendrait légale ou applicable si une partie de celle-ci était supprimée, cette partie sera considérée comme supprimée, et le reste de la disposition continuera à produire ses effets.


                    </p>
                </section><section className="mt-6">
                    <h3 className="text-xl font-semibold">20. Cession</h3>
                    <p className="mt-2 text-gray-700">
                    
Vous acceptez par les présentes que nous puissions céder, transférer, sous-traiter ou traiter de toute autre manière nos droits et/ou obligations en vertu des présentes conditions générales.

Vous ne pouvez pas, sans notre consentement écrit préalable, céder, transférer, sous-traiter ou traiter de toute autre manière vos droits et/ou obligations en vertu des présentes conditions générales.


                    </p>
                </section>

                <section className="mt-6">
                    <h3 className="text-xl font-semibold">21. Droits des tiers</h3>
                    <p className="mt-2 text-gray-700">
                    Un contrat conclu dans le cadre des présentes conditions générales est à notre avantage et à votre avantage, et n'est pas destiné à bénéficier à un tiers ou à être exécutoire par un tiers.

L'exercice des droits des parties dans le cadre d'un contrat conclu en vertu des présentes conditions générales n'est soumis au consentement d'aucun tiers.


                    </p>
                </section>
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">22. Loi applicable et juridiction
</h3>
                    <p className="mt-2 text-gray-700">
                    Les présentes conditions générales sont régies et interprétées conformément au droit du Territoire.

Tout litige relatif aux présentes conditions générales est soumis à la compétence exclusive des tribunaux compétents de Marrakech.


                    </p>
                </section>
                <section className="mt-6">
                    <h3 className="text-xl font-semibold">23.Les Coordonnées de notre société
</h3>
                    <p className="mt-2 text-gray-700">
                    La Marketplace IDY MOROCCO sous le nom de domaine www.idymorocco.com est exploitée par :

Dyla Art SARL, Société à responsabilité limitée au capital de 100000 DH, immatriculée au Registre de Commerce de Marrakech sous le numéro : 125661, rue Mouslim lot Boukar, Bab Doukkala, Marrakech, Maroc.


                    </p>
                </section>
                

                {/* Repeat for all other sections */}
                
                <footer className="mt-6 text-gray-600">
                    <p>
                    Vous pouvez nous contacter en utilisant notre adresse email contact@idymorocco.com.
                    </p>
                </footer>
            </div>
        </>
    );
};

export default TermsAndConditions;
