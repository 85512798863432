import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const VerifyEmail = () => {
  const { email, hash } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await fetch(`https://bk.idymorocco.com/api/verify-email?email=${email}&hash=${hash}`);

        if (!response.ok) {
          throw new Error('Email verification failed');
        }

        Swal.fire({
          title: 'Email Verified!',
          text: 'Your email has been successfully verified.',
          icon: 'success',
          confirmButtonText: 'OK',
        });

        navigate('/login');
      } catch (error) {
        Swal.fire({
          title: 'Verification Failed',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'OK',
        });
        navigate('/register');
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [email, hash, navigate]);

  return (
    <div>
      {loading ? <p>Loading...</p> : <p>Verification process complete.</p>}
    </div>
  );
};

export default VerifyEmail;