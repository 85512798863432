import React from 'react';
import Navbar from "../../Components/Home/Navbar";

const PrivacyPolicy = () => {
    return (
        <><Navbar /><div className="container mx-auto p-20 font-hubballi">
            <h1 className="text-3xl font-bold mb-6 text-center">Politique de Confidentialité</h1>
            <ol className=" space-y-4">
                <li>
                    <h2 className="font-semibold">1. Introduction</h2>
                    <p>
                        Chez IDY Morocco, nous valorisons votre confiance et nous nous engageons à protéger vos informations personnelles. Cette politique de confidentialité décrit comment nous collectons, utilisons, stockons et protégeons vos données lorsque vous utilisez notre site e-commerce. En visitant notre site, vous acceptez les pratiques décrites dans cette politique.
                    </p>
                </li>
                <li>
                    <h2 className="font-semibold">2. Informations que Nous Collectons</h2>
                    <p>
                        Nous recueillons plusieurs types d'informations pour fournir et améliorer nos services :
                    </p>
                    <ul className="list-disc list-inside ml-6">
                        <li>Informations Personnelles : Nom, adresse, e-mail, numéro de téléphone, informations de paiement.</li>
                        <li>Informations de Navigation : Adresse IP, type de navigateur, pages visitées, temps de visite et autres données analytiques.</li>
                    </ul>
                </li>
                <li>
                    <h2 className="font-semibold">3. Utilisation de Vos Données</h2>
                    <p>
                        Vos informations sont utilisées pour :
                    </p>
                    <ul className="list-disc list-inside ml-6">
                        <li>Traiter et livrer vos commandes.</li>
                        <li>Communiquer avec vous concernant vos achats, promotions, et mises à jour de notre site.</li>
                        <li>Améliorer notre site et nos services.</li>
                        <li>Respecter les obligations légales et réglementaires.</li>
                    </ul>
                </li>
                <li>
                    <h2 className="font-semibold">4. Protection de Vos Données</h2>
                    <p>
                        Nous mettons en œuvre diverses mesures de sécurité pour protéger vos informations :
                    </p>
                    <ul className="list-disc list-inside ml-6">
                        <li>Cryptage SSL : Toutes les transactions sont sécurisées par un cryptage SSL.</li>
                        <li>Stockage Sécurisé : Vos données sont stockées sur des serveurs sécurisés.</li>
                        <li>Accès Restreint : Seuls les employés autorisés peuvent accéder à vos informations personnelles.</li>
                    </ul>
                </li>
                <li>
                    <h2 className="font-semibold">5. Partage de Vos Données</h2>
                    <p>
                        Nous ne vendons ni ne louons vos informations personnelles à des tiers. Nous pouvons partager vos données avec des partenaires de confiance pour :
                    </p>
                    <ul className="list-disc list-inside ml-6">
                        <li>Traiter vos commandes et livraisons.</li>
                        <li>Effectuer des analyses de site pour améliorer nos services.</li>
                        <li>Se conformer à la loi ou répondre à des demandes légales.</li>
                    </ul>
                </li>
                <li>
                    <h2 className="font-semibold">6. Utilisation des Cookies</h2>
                    <p>
                        Nous utilisons des cookies pour :
                    </p>
                    <ul className="list-disc list-inside ml-6">
                        <li>Fonctionnalités Essentielles : Assurer le bon fonctionnement de notre site.</li>
                        <li>Performance : Analyser et améliorer les performances de notre site.</li>
                        <li>Personnalisation : Mémoriser vos préférences et améliorer votre expérience utilisateur.</li>
                        <li>Publicité : Afficher des publicités pertinentes basées sur vos intérêts.</li>
                    </ul>
                </li>
                <li>
                    <h2 className="font-semibold">7. Gestion des Cookies</h2>
                    <p>
                        Vous pouvez contrôler et gérer les cookies via les paramètres de votre navigateur. Cependant, la désactivation des cookies peut affecter votre expérience sur notre site.
                    </p>
                </li>
                <li>
                    <h2 className="font-semibold">8. Vos Droits</h2>
                    <p>
                        Vous avez le droit de :
                    </p>
                    <ul className="list-disc list-inside ml-6">
                        <li>Accéder à vos informations personnelles.</li>
                        <li>Demander la correction ou la suppression de vos données.</li>
                        <li>Vous opposer au traitement de vos informations personnelles.</li>
                        <li>Demander la portabilité de vos données.</li>
                    </ul>
                    <p>
                        Pour exercer ces droits, veuillez nous contacter à l'adresse e-mail suivante : contact@idymorocco.com
                    </p>
                </li>
                <li>
                    <h2 className="font-semibold">9. Mises à Jour de cette Politique</h2>
                    <p>
                        Nous pouvons mettre à jour cette politique de confidentialité pour refléter les changements dans nos pratiques. Consultez régulièrement cette page pour rester informé.
                    </p>
                </li>
                <li>
                    <h2 className="font-semibold">10. Contactez-Nous</h2>
                    <p>
                        Pour toute question concernant notre politique de confidentialité, veuillez nous contacter :
                    </p>
                    <ul className="list-disc list-inside ml-6">
                        <li>Adresse e-mail : contact@idymorocco.com</li>
                        <li>Chat en ligne : Lien vers chat en ligne</li>
                        <li>Téléphone : +212 661 514 741</li>
                    </ul>
                    <p>
                        Nous sommes là pour garantir que votre expérience avec IDY Morocco soit à la fois enrichissante et sécurisée.
                    </p>
                </li>
            </ol>
        </div></>
    );
};

export default PrivacyPolicy;
