import React, { useState, useEffect } from 'react';
import "../../Styles/Description.css";
import Loading from "../../Components/Home/Loading";
import { useTranslation } from "react-i18next";

const ProductPage = ({ currency }) => {
  const [products, setProducts] = useState([]);
  const [showAll] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // Fetch the category
        const categoryResponse = await fetch(`https://bk.idymorocco.com/api/categories/filter/2`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
          }
        });
  
        // Parse the JSON response
        const categoryResult = await categoryResponse.json();
        console.log("Category Result:", categoryResult);
  
        // Check if we have the Habillement category
        const habillementCategory = categoryResult[0];
  
        if (habillementCategory && habillementCategory.sub_category) {
          // Initialize an array to hold all products
          const allProducts = [];
  
          // Iterate through the subcategories
          habillementCategory.sub_category.forEach(subCategory => {
            // For each subcategory, check for products
            subCategory.sub_sub_category.forEach(subSubCategory => {
              if (subSubCategory.product) {
                allProducts.push(...subSubCategory.product);
              }
            });
          });
  
          // Set the state with all found products
          console.log("All Products:", allProducts);
          setProducts(allProducts);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
  
    fetchProducts();
  }, []);

  const displayedProducts = Array.isArray(products) ? (showAll ? products : products.slice(0, 8)) : [];
  console.log("Displayed Products:", displayedProducts);

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6 pt-4 pb-12">
        {displayedProducts.length > 0 ? (
          displayedProducts.map((product) => (
            <div key={product.id} className="flex flex-col items-center">
              <button 
                className="relative w-full h-72 overflow-hidden bg-cover bg-no-repeat shadow-md"
                onClick={() => window.location.href = `/product/${product.id}`}
              >
                <img
                  src={
                    product.images.length > 0
                      ? product.images[0].url
                      : `https://via.placeholder.com/300?text=${product.name}`
                  }
                  className="object-cover w-full h-full transition-transform duration-300 ease-in-out hover:scale-110"
                  alt={product.name}
                />
              </button>
              <div className="pt-3 w-full text-center font-hubballi">
                <p className="text-lg font-medium">{product.name}</p>
              </div>
              <div className="pt-2 text-center font-hubballi">
                <p className="text-md">
                  {parseFloat(product.base_price).toFixed(2)}{" "}
                  {currency ? currency.toUpperCase() : "MAD"}
                  {product.compare_at_price && product.compare_at_price !== product.base_price && (
                    <span className="line-through text-gray-500 ml-2">
                      {parseFloat(product.compare_at_price).toFixed(2)}
                      {currency ? currency.toUpperCase() : "MAD"}
                    </span>
                  )}
                </p>
              </div>
            </div>
          ))
        ) : displayedProducts.length === 0 ? (
          <p  role="alert" className="col-span-full font-hubballi mt-4 text-center bg-gray-500 text-white p-2 rounded">
            {t("NO-PRODUCTS-AVAILABLE")} {/* Add translation key here */}
          </p>
        ) : (
          <p className="text-center col-span-full">
            <Loading />
          </p>
        )}
      </div>

      <div className="flex items-center justify-center mt-8">
        <button
          onClick={() => (window.location.href = `/all-products`)}
          className="bg-black text-white hover:bg-white hover:text-black py-3 px-8 rounded-md font-hubballi border border-gray-400 transition-colors duration-300"
        >
          {t("VIEW-ALL-PRODUCTS")}
        </button>
      </div>
    </div>
  );
};

export default ProductPage;
