import React from 'react';
import Navbar from "../../Components/Home/Navbar";
const ReturnPolicy = () => {
    return (
        <><Navbar /><div className="container mx-auto p-20 font-hubballi">
            <h1 className="text-3xl font-bold mb-6">Politique de Retour</h1>
            <p className="mb-4">
                Chez IDY Morocco, votre satisfaction est notre priorité. Nous comprenons que des situations peuvent nécessiter le retour d'un article. Voici notre politique de retour, conçue pour vous offrir toute la clarté et le soutien nécessaires.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Éligibilité au Retour</h2>
            <p className="mb-4">
                Vous pouvez retourner votre commande, sauf pour les cas suivants :
            </p>
            <ul className="list-disc ml-6 mb-4">
                <li>Articles soldés</li>
                <li>Articles personnalisés</li>
                <li>Produits périssables (tels que les aliments)</li>
                <li>Produits cosmétiques et autres articles ne pouvant être revendus pour des raisons d'hygiène et de protection de la santé</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Délais de Retour</h2>
            <h3 className="font-semibold mt-4">Retour pour simple changement d'avis :</h3>
            <p className="mb-4">
                Vous disposez de 14 jours à compter de la réception de votre commande pour nous informer de votre souhait de retourner l'article. Contactez-nous par email à <a href="mailto:contact@idymorocco.com" className="text-blue-500">contact@idymorocco.com</a> et retournez le produit dans les 14 jours suivant cette notification.
            </p>
            <h3 className="font-semibold mt-4">Retour pour article défectueux ou endommagé :</h3>
            <p className="mb-4">
                Si l'article reçu est défectueux ou endommagé, vous avez également 14 jours à compter de la réception de la commande pour nous en informer par email à <a href="mailto:contact@idymorocco.com" className="text-blue-500">contact@idymorocco.com</a>. Nous pouvons échanger l'article (sous réserve de disponibilité) ou procéder à un remboursement.
            </p>
            <p className="mb-4">
                Nous ne remplaçons les produits que s’ils sont défectueux ou endommagés.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Processus de Retour</h2>
            <p className="mb-4">
                Les produits doivent être retournés dans leur emballage d'origine et ne doivent pas avoir été utilisés. Une preuve d'achat doit accompagner l'article retourné.
            </p>
            <p className="mb-4">
                Une fois l'article retourné reçu, nous vous informerons par email de l'acceptation ou du refus du remboursement. En cas d'acceptation, seul le montant de l'article sera remboursé via la solution de paiement que vous avez choisie. Les frais d’expédition ne sont pas remboursables.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Frais de Retour</h2>
            <p className="mb-4">
                Les frais de retour sont à la charge de l'Acheteur. Si le produit est perdu par le transporteur lors du retour, l'Acheteur demeure seul responsable et ne pourra prétendre à aucun remboursement du montant du produit retourné.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Délais de livraison</h2>
            <p className="mb-4">
                Nos délais de livraison varient entre 3 et 7 jours ouvrables. Si votre produit est réalisé sur commande, le délai d’expédition sera indiqué. Vous serez informé du temps de livraison spécifique à chaque commande.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Frais de livraison</h2>
            <p className="mb-4">
                Les frais de livraison sont à votre charge et dépendent du poids, des dimensions du produit et du pays de destination. Les frais de livraison sont calculés individuellement pour chaque article.
            </p>

            <h2 className="text-2xl font-semibold mt-6 mb-4">Service Client</h2>
            <p className="mb-4">
                Nous sommes disponibles pour répondre à toutes vos questions sur les retours et pour vous assister tout au long du processus. N'hésitez pas à nous contacter :
            </p>
            <ul className="list-none ml-6 mb-4">
                <li>Adresse e-mail : <a href="mailto:contact@idymorocco.com" className="text-blue-500">contact@idymorocco.com</a></li>
                <li>Chat en ligne : [lien vers le chat en ligne]</li>
                <li>Téléphone : +212 664 514 743</li>
            </ul>
            <p>
                Chez IDY Morocco, nous nous engageons à vous offrir une expérience d'achat positive et à garantir la satisfaction de nos clients. Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter.
            </p>
        </div></>
    );
};

export default ReturnPolicy;
