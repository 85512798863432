import React from "react";
import "../../Styles/Blogpage.css"; // Adjust the path to your CSS file
import Header from "../../Components/Home/Header"; // Adjust the path as necessary
import Navbar from "../../Components/Home/Navbar"; // Adjust the path as necessary
import rugs from "../../Assets/rugs.png";
import ahwach from "../../Assets/ahwach.png";
import product from "../../Assets/Product.png";
import kasbah from "../../Assets/kasbah.jpg";
import { useTranslation } from "react-i18next"; // Import hook for translation
const Blogpage = () => {
  const { t } = useTranslation(); // Initialize translation hook
  return (
    <div>

      <Navbar />
      <div className="blog-page">
        <header className="blog-header">
          <h1>{t("OUR BLOG")}</h1>
        </header>
        <div className="blog-content">
        <div className="blog-article-large flex flex-col lg:flex-row items-center gap-4 lg:gap-8 p-4">
  {/* Large Image */}
  <img
    src={rugs}
    alt="rugs"
    className="blog-image-large w-full lg:w-1/2 h-64 object-cover  "
  />

  {/* Text Overlay */}
  <div className="blog-text-overlay flex flex-col items-center lg:items-start text-center lg:text-left">
  <h2 className="text-sm lg:text-4xl font-bold">
    {t('YOUR GUIDE TO CREATING A COSY AUTUMN HOME IN 5 EASY STEPS')}
  </h2>
  <a
    href="#read-more-large"
    className="read-more-button px-6 py-2 text-white bg-black hover:text-white hover:bg-black transition duration-300"
  >
    {t('READ MORE')}
  </a>
</div>
</div>

<div className="blog-article-small-container relative max-w-4xl mx-auto p-4 flex flex-wrap gap-4">
  {/* Article 1 */}
  <div className="blog-article-small flex-1 min-w-full sm:min-w-[48%] lg:min-w-[30%] bg-white overflow-hidden">
    <img
      src={ahwach}
      alt="BERBER ‘AHWACH"
      className="blog-image-small w-full h-48 object-cover transition duration-300 ease-in-out hover:scale-110"
    />
    <div className="blog-article-content p-4">
      <h2 className="text-xl font-bold">{t("article_1_title")}</h2>
      <p className="mt-2 text-gray-600">
      {t("article_1_description")}
      </p>
      <a href="#read-more" className="read-more text-blue-500 mt-2 inline-block">
      {t("read_more")}
      </a>
    </div>
  </div>

  {/* Article 2 */}
  <div className="blog-article-small flex-1 min-w-full sm:min-w-[48%] lg:min-w-[30%] bg-white overflow-hidden ">
    <img
      src={product}
      alt="Crafts"
      className="blog-image-small w-full h-48 object-cover"
    />
    <div className="blog-article-content p-4">
      <h2 className="text-xl font-bold">{t("article_2_title")}</h2>
      <p className="mt-2 text-gray-600">
      {t("article_2_description")}
      </p>
      <a href="#read-more" className="read-more text-blue-500 mt-2 inline-block">
      {t("read_more")}
      </a>
    </div>
  </div>

  {/* Article 3 */}
  <div className="blog-article-small flex-1 min-w-full sm:min-w-[48%] lg:min-w-[30%] bg-white overflow-hidden ">
    <img
      src={kasbah}
      alt="Kasbah"
      className="blog-image-small w-full h-48 object-cover"
    />
    <div className="blog-article-content p-4">
      <h2 className="text-xl font-bold">{t("article_3_title")}</h2>
      <p className="mt-2 text-gray-600">
      {t("article_3_description")}
      </p>
      <a href="#read-more" className="read-more text-blue-500 mt-2 inline-block">
      {t("read_more")}
      </a>
    </div>
  </div>
</div>

        </div>
      </div>
    </div>
  );
};

export default Blogpage;
