/* eslint-disable jsx-a11y/anchor-is-valid */
// src/Components/Footer.js
import React from "react";
import "../../Styles/Footer.css";
import visa from "../../Assets/Icons payement/visa.png";
import card from "../../Assets/Icons payement/card.png";
import logo1 from "../../Assets/logo1.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation(); 
  const { i18n } = useTranslation();
  return (
    <footer className={`footer bg-gray-100 p-6 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
    dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
      <div className="container mx-auto">
        {/* <!-- Footer Columns --> */}
        <div className="footer-columns flex flex-col md:flex-row md:justify-between gap-8">
          {/* <!-- Information Column --> */}
          <div className="footer-column mb-8 md:mb-0">
            <h4 className="text-lg font-hubballi">{t("INFORMATION")}</h4>
            <ul className="space-y- mt-2">
              <Link to="/about-us">
                <li>
                  <a href="" className="text-gray-700 hover:text-[#ccb898]">{t("About Us")}</a>
                </li>
              </Link>
              <Link to="/Contact-us">
                <li>
                  <a href="" className="text-gray-700 hover:text-[#ccb898]">{t("Contact Us")}</a>
                </li>
              </Link>
              <Link to="/blog">
                <li>
                  <a href="" className="text-gray-700 hover:text-[#ccb898]">{t("Blog")}</a>
                </li>
              </Link>
            </ul>
          </div>

          {/* <!-- Customer Service Column --> */}
          <div className="footer-column mb-8 md:mb-0">
            <h4 className="text-lg font-hubballi">{t("CUSTOMER SERVICE")}</h4>
            <ul className="space-y- mt-2">
              <Link to="/Terms-Conditions">
                <li>
                  <a href="#terms" className="text-gray-700 hover:text-[#ccb898]">
                    {t("Terms & Conditions")}
                  </a>
                </li>
              </Link>
              <Link to="/Faq">
                <li>
                  <a href="#faq" className="text-gray-700 hover:text-[#ccb898]">{t("FAQ")}</a>
                </li>
              </Link>
              <Link to="/Returns-Delivery">
                <li>
                  <a href="#returns" className="text-gray-700 hover:text-[#ccb898]">{t("Returns & Delivery")}</a>
                </li>
              </Link>
              <Link to="/Privacy-Policy">
                <li>
                  <a href="#privacy" className="text-gray-700 hover:text-[#ccb898]">{t("Privacy Policy")}</a>
                </li>
              </Link>
            </ul>
          </div>

          {/* <!-- Follow Us Column --> */}
          <div className="footer-column">
            <h4 className="text-lg font-hubballi">{t("FOLLOW US")}</h4>
            <p className="mt-2 text-black">
              {t("For more style inspiration, follow us on")}{" "}
              <a href="#instagram" className="underline text-gray-900 hover:text-gray-600">
                {t("Instagram")}
              </a>{" "}
              {t("and tag us with your purchases to be featured.")}
            </p>
            <div className="container mx-auto flex justify-between items-center">
              {/* <!-- Social Icons --> */}
              <div className="social-icons flex space-x-4">
                <a href="#facebook" className="text-gray-500 hover:text-[#ccb898]">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#instagram" className="text-gray-500 hover:text-[#ccb898]">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="#pinterest" className="text-gray-500 hover:text-[#ccb898]">
                  <i className="fab fa-pinterest"></i>
                </a>
              </div>

              {/* <!-- Logo --> */}
              <div>
                <img className="w-20 h-auto rotate-3" src={logo1} alt={t("logo")} />
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Footer Bottom Section with Logo --> */}
        <div className="mt-6 flex flex-col md:flex-row justify-between items-center">
          <span className="text-gray-500 text-sm mb-4 md:mb-0">&copy; {t("IDY MOROCCO")}</span>
          <div className="flex space-x-4">
            <img src={visa} alt={t("Visa")} className="h-10" />
            <img src={card} alt={t("Mastercard")} className="h-10" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
