import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Home/Navbar";
import "../../Styles/Description.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Home/Loading";
import { useTranslation } from "react-i18next"; // Import useTranslation
const CartPage = ({ currency }) => {
  const [cartItems, setCartItems] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true); // Added loading state
  const [exchangeRate, setExchangeRate] = useState(1); // Default exchange rate
  const { t } = useTranslation(); // Initialize translation function
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    fetchCartItems();
  }, [currency]);

  const fetchCartItems = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError(t("User not authenticated. Please log in."));
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get("https://bk.idymorocco.com/api/carts", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept": "application/json",
        },
        params: {
          currency: currency,
        },
      });
      if (response.data && response.data.data) {
        setCartItems(response.data.data);
        setExchangeRate(response.data.exchangeRate || 1);
        setError("");
      } else {
        setError("Failed to fetch cart items.");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setError("An error occurred while fetching the cart items.");
    } finally {
      setLoading(false);
    }
  };

  const increaseQuantity = (index) => {
    const newCartItems = [...cartItems];
    newCartItems[index].quantity_ordered += 1;
    setCartItems(newCartItems);
    updateCartItem(newCartItems[index]);
  };

  const decreaseQuantity = (index) => {
    const newCartItems = [...cartItems];
    if (newCartItems[index].quantity_ordered > 1) {
      newCartItems[index].quantity_ordered -= 1;
      setCartItems(newCartItems);
      updateCartItem(newCartItems[index]);
    }
  };

  const updateCartItem = async (item) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("User not authenticated. Please log in.");
      return;
    }

    try {
      const response = await axios.put(
        `https://bk.idymorocco.com/api/carts/update/${item.id}`,
        item,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (
        response.status === 200 &&
        response.data &&
        response.data.status === 200
      ) {
        setError("");
        fetchCartItems(); // Refresh cart items
      } else {
        setError("Failed to update cart item.");
      }
    } catch (error) {
      console.error("Error updating cart item:", error);
      setError("An error occurred while updating the cart item.");
    }
  };

  const deleteCartItem = async (id) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("User not authenticated. Please log in.");
      return;
    }

    try {
      const response = await axios.delete(
        `https://bk.idymorocco.com/api/carts/remove/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 204) {
        setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
        setError("");
      } else {
        setError("Failed to delete cart item.");
      }
    } catch (error) {
      console.error("Error deleting cart item:", error);
      setError("An error occurred while deleting the cart item.");
    }
  };

  const handleCheckout = () => {
    navigate("/checkout");
  };  

  const cartstyle = (() => {
    switch (i18n.language) {
      case 'fr':
        return "space-y-2 sm:mr-[230px] w-full sm:w-auto"; // Styles for French
      case 'ar':
        return " space-y-2 sm:ml-[220px] w-full sm:w-auto"; // Styles for Arabic
      default:
        return "space-y-2 sm:mr-[230px] w-full sm:w-auto"; // Styles for English
    }
  })();
  const removestyle = (() => {
    switch (i18n.language) {
      case 'fr':
        return "pt-2 text-lg text-center sm:text-left"; // Styles for French
      case 'ar':
        return "pt-2 text-lg  sm:ml-[20px] text-center sm:text-left"; // Styles for Arabic
      default:
        return "pt-2 text-lg text-center sm:text-left"; // Styles for English
    }
  })();
  const productnamestyle = (() => {
    switch (i18n.language) {
      case 'fr':
        return "text-sm w-[200px] font-hubballi uppercase"; // Styles for French
      case 'ar':
        return "text-sm w-[200px] mr-2 font-hubballi uppercase"; // Styles for Arabic
      default:
        return "text-sm w-[200px] font-hubballi uppercase"; // Styles for English
    }
  })();
  const productpricestyle = (() => {
    switch (i18n.language) {
      case 'fr':
        return "text-sm text-gray-600 font-hubballi"; // Styles for French
      case 'ar':
        return "text-sm text-gray-600 mr-2 font-hubballi"; // Styles for Arabic
      default:
        return "text-sm text-gray-600 font-hubballi"; // Styles for English
    }
  })();
  return (
    <div className={` ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
    dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
      <Navbar cartItemsCount={cartItems.length} />
      <div className="container mx-auto px-4 md:px-8 lg:pl-32 lg:pr-32 py-8">
  {/* Cart Title */}
  <h1 className="text-3xl font-hubballi mb-4 text-center">{t("title")}</h1>

  {/* Show loading spinner or message */}
  {loading ? (
    <div className="text-center py-4">
      <div className="loader"></div>
      <Loading />
    </div>
  ) : (
    <>
      {/* Cart Header: Hidden on mobile */}
      <div className="cartline hidden sm:flex justify-between flex-wrap text-lg font-hubballi uppercase py-2">
        <span className="w-1/3 sm:w-auto">{t("product")}</span>
        <span className="w-1/3 sm:w-auto mr-4">{t("quantity")}</span>
        <span className="w-1/3 sm:w-auto text-right">{t("total")}</span>
      </div>

      {error && <p className="text-red-500 text-center font-hubballi">{error}</p>}

      {/* Cart Items */}
      {Array.isArray(cartItems) && cartItems.length > 0 ? (
        cartItems.map((item, index) => (
          <div
            key={item.id}
            className="flex flex-col sm:flex-row justify-between items-center py-4 border-b"
          >
            {/* Product Info */}
            
            <div className="flex items-center space-x-2 mb-4 sm:mb-0">
              <img
                src={
                  item.product.images && item.product.images.length > 0
                    ? item.product.images[0].url
                    : `https://via.placeholder.com/300?text=${item.product.name}`
                }
                alt={`Product ${item.product_id}`}
                className="w-24 h-24 object-cover"
              />
              <div>
                <h3 className={productnamestyle}>
                  {item.product.name}
                </h3>
                <p className={productpricestyle}>
                  {(item.price * exchangeRate).toFixed(2)}
                  {currency.toUpperCase() || "MAD"}
                </p>
              </div>
            </div>

            <div className={cartstyle}>
  {/* Quantity Controls */}
  <div className="flex items-center justify-center sm:justify-start space-x-2">
    <button
      className="text-xl px-2 py-1 font-hubballi"
      onClick={() => decreaseQuantity(index)}
    >
      -
    </button>
    <input
      type="text"
      value={item.quantity_ordered}
      readOnly
      className="border w-12 text-center"
    />
    <button
      className="text-xl px-2 py-1 font-hubballi"
      onClick={() => increaseQuantity(index)}
    >
      +
    </button>
  </div>

  {/* Remove Button */}
  <div className={removestyle}>
    <div className="bl-4">
      <button
        onClick={() => deleteCartItem(item.id)}
        className="text-black pl-0 sm:pl-10 font-hubballi underline"
      >
        {t("remove")}
      </button>
    </div>
  </div>
</div>


            {/* Total Price */}
            <div className="mt-2 sm:mt-0 text-right font-hubballi">
              <span>
                {(item.price * item.quantity_ordered * exchangeRate).toFixed(2)}
                {currency.toUpperCase() || "MAD"}
              </span>
            </div>
          </div>
        ))
      ) : (
        <p
          role="alert"
          className="mt-4 font-hubballi text-center bg-gray-500 text-white p-2 rounded"
        >
          {t("noItems")}
        </p>
      )}

      {/* Cart Total */}
      <div className="text-right mt-6">
        <div className="mb-4">
          <span className="text-lg font-hubballi uppercase">{t("total")}:</span>
          <span className="text-lg font-hubballi ml-2">
            {cartItems
              .reduce(
                (total, item) =>
                  total + item.price * item.quantity_ordered * exchangeRate,
                0
              )
              .toFixed(2)}
            {currency.toUpperCase() || "MAD"}
          </span>
        </div>
        <h4 className="text-sm font-hubballi">
        {t("shippingTaxes")}
        </h4>
        <button
  onClick={handleCheckout}
  disabled={cartItems.length === 0} // Disable when no items
  className={`px-6 py-3 uppercase text-sm font-hubballi mt-6 ${
    cartItems.length === 0
      ? "bg-gray-400 cursor-not-allowed" // Disabled style
      : "bg-black text-white"
  }`}
>
  {t("checkout")}
</button>
      </div>
    </>
  )}
</div>

    </div>
  );
};

export default CartPage;
