import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from "../../Components/Home/Navbar";
const SupportTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('open'); // Default status
  const [storeId] = useState(1); // Example store ID (modify if needed)
  const [error, setError] = useState(null);

  const token = localStorage.getItem('token'); // Replace with your token logic

  // Fetch existing support tickets
  const fetchSupportTickets = async () => {
    try {
      const response = await axios.get('https://bk.idymorocco.com/api/support-tickets', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTickets(response.data);
    } catch (err) {
      console.error('Error fetching tickets:', err);
      setError('Unable to fetch support tickets.');
    }
  };

  // Submit new support ticket
  const handleSubmit = async (e) => {
    e.preventDefault();

    const ticketData = {
      subject,
      message,
      status, // Make sure status is updated correctly
      store_id: storeId,
    };


    try {
      const response = await axios.post(
        'https://bk.idymorocco.com/api/support-tickets',
        ticketData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
             "Accept": "application/json",
          },
        }
      );
      setTickets([...tickets, response.data]); // Add new ticket to the list
      setSubject('');
      setMessage('');
      setStatus('open'); // Reset to default
    } catch (err) {
      console.error('Error creating ticket:', err);
      setError('Unable to create support ticket.');
    }
  };

  useEffect(() => {
    fetchSupportTickets();
  }, []);

  return (
    <><Navbar /><div className="container mx-auto p-6">
          <h1 className="text-3xl font-bold mb-4">Support Tickets</h1>

          {error && <p className="text-red-500">{error}</p>}

          <form onSubmit={handleSubmit} className="space-y-4 mb-6">
              <div>
                  <label className="block font-semibold">Subject</label>
                  <input
                      type="text"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      className="w-full p-2 border rounded"
                      required />
              </div>

              <div>
                  <label className="block font-semibold">Message</label>
                  <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="w-full p-2 border rounded"
                      rows="4"
                      required />
              </div>

              <div>
                  <label className="block font-semibold">Status</label>
                  <select
                      value={status}
                      onChange={(e) => {
                          console.log('Status selected:', e.target.value); // Debugging to check if status updates correctly
                          setStatus(e.target.value); // Ensure state is updated
                      } }
                      className="w-full p-2 border rounded"
                      required
                  >
                      <option value="open">Open</option>
                      <option value="in progress">In Progress</option>
                      <option value="closed">Closed</option>
                      <option value="pending">Pending</option>
                  </select>
              </div>

              <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                  Submit Ticket
              </button>
          </form>

          <h2 className="text-2xl font-bold mb-4">Existing Tickets</h2>
          {tickets.length > 0 ? (
              <ul className="space-y-4">
                  {tickets.map((ticket) => (
                      <li key={ticket.id} className="p-4 border border-gray-300 rounded">
                          <h3 className="text-xl font-semibold">{ticket.subject}</h3>
                          <p>{ticket.message}</p>
                          <p><strong>Status:</strong> {ticket.status}</p>
                      </li>
                  ))}
              </ul>
          ) : (
              <p>No support tickets available.</p>
          )}
      </div></>
  );
};

export default SupportTickets;
