import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
const CashondeliveryForm = ({
  currency = 'mad',
  onSuccess,
  couponCode,
  onCityChange,
  setSelectedShippingRate,
  onCountryChange,
  totalAmount
}) => {
    const { t } = useTranslation(); 
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    address2: "",
    postalCode: "",
    city: "",
    country: "",
  });
  const [shippingRates, setShippingRates] = useState([]);
  const [selectedShippingRate, setSelectedShippingRateLocal] = useState(0);
  const [total, setTotal] = useState(totalAmount);
  const [availableCountries, setAvailableCountries] = useState([]);
  const [availableCities, setAvailableCities] = useState([]);
  const [unavailableProducts, setUnavailableProducts] = useState([]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchShippingZones = async () => {
      try {
        const response = await fetch(
          "https://bk.idymorocco.com/api/shipping-zones",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept": "application/json",
            },
          }
        );
        const data = await response.json();
        setShippingRates(data);
      } catch (error) {
        console.error("Error fetching shipping zones:", error);
      }
    };

    fetchShippingZones();
  }, [token]);

  useEffect(() => {
    const storeIds = JSON.parse(localStorage.getItem("store_ids")) || [];
    const countries = shippingRates
      .filter((zone) => storeIds.includes(zone.store_id))
      .map((zone) => zone.country);

    setAvailableCountries([...new Set(countries)]);
  }, [shippingRates]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name.toLowerCase() === "country") {
      onCountryChange(e);
      const selectedCountryZones = shippingRates.filter(
        (zone) => zone.country === value
      );
      const cities = selectedCountryZones.flatMap((zone) =>
        zone.shipping_rates.map((rate) => rate.rate_name)
      );
      setAvailableCities([...new Set(cities)]);
    }

    if (name === "city") {
      onCityChange(e);
    }
  };

  useEffect(() => {
    const baseTotal = 0;
    setTotal(baseTotal + selectedShippingRate);
  }, [selectedShippingRate]);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    try {
      const response = await fetch("https://bk.idymorocco.com/api/cash-on-delivery", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Accept": "application/json",
        },
        body: JSON.stringify({
          currency: currency || "mad",
          first_name: formData.firstName,
          last_name: formData.lastName,
          phone: formData.phone,
          address: formData.address,
          address2: formData.address2,
          postal_code: formData.postalCode,
          city: formData.city,
          coupon_code: couponCode,
          country: formData.country,
          payment_type: "cash on delivery",
          total:totalAmount
        }),
      });

      const text = await response.text();
      const data = JSON.parse(text);

      if (data.message.toLowerCase().includes("success")) {
        alert("Order placed successfully!");
        setFormData({
          firstName: "",
          lastName: "",
          phone: "",
          address: "",
          address2: "",
          postalCode: "",
          city: "",
          country: "",
        });
        onSuccess();
      } else {
        alert("Order failed: " + (data.message || "Unknown error"));
      }
    } catch (err) {
      console.error("Error parsing JSON:", err);
      alert("Error parsing server response");
    }

    setLoading(false);
  };

  return (
    <form
  onSubmit={handleSubmit}
  className="max-w-lg mx-auto  bg-white border p-6 md:p-10 md:mr-[100px]"
>
  <div className="mb-4">
    <input
      type="email"
      className="w-full p-3 border border-gray-300 hover:border-[#ccb898] focus:outline-none "
      placeholder={t("Email")}
    />
  </div>

  <div className="flex items-center mb-4">
    <input
      id="default-checkbox"
      type="checkbox"
      value=""
      className="w-4 h-4"
    />
    <label
      htmlFor="default-checkbox"
      className="ms-2 text-sm font-medium text-black"
    >
      {t("Email me with news and offers")}
    </label>
  </div>

  <h2 className="font-bold mt-6">{t("Delivery")}</h2>

  {/* <!-- Responsive grid layout --> */}
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
    <select
      id="country"
      name="country"
      value={formData.country}
      onChange={handleChange}
      className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none "
    >
      <option value="" disabled>
        {t("Country/Region")}
      </option>
      {availableCountries.map((country, index) =>
        country.toLowerCase() === "maroc" ? (
          <option key={index} value={country}>
            {country}
          </option>
        ) : null
      )}
    </select>

    <input
      type="text"
      className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none "
      name="firstName"
      value={formData.firstName}
      onChange={handleChange}
      placeholder={t("First Name")}
    />

    <input
      type="text"
      className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none "
      name="lastName"
      value={formData.lastName}
      onChange={handleChange}
      placeholder={t("Last Name")}
    />

    <input
      type="text"
      className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none "
      placeholder={t("Company (optional)")}
    />

    <input
      type="text"
      className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none "
      name="address"
      value={formData.address}
      onChange={handleChange}
      placeholder={t("Address")}
    />

    <input
      type="text"
      className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none "
      name="address2"
      value={formData.address2}
      onChange={handleChange}
      placeholder={t("Apartment, suite, etc")}
    />

    <input
      type="text"
      className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none "
      name="postalCode"
      value={formData.postalCode}
      onChange={handleChange}
      placeholder={t("Postal Code")}
    />

    <select
      id="city"
      name="city"
      value={formData.city}
      onChange={handleChange}
      className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none "
    >
      <option value="" disabled>
      {t("City")}
      </option>
      {availableCities.map((city, index) => (
        <option key={index} value={city}>
          {city}
        </option>
      ))}
    </select>

    <input
      type="tel"
      name="phone"
      value={formData.phone}
      onChange={handleChange}
      className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none "
      placeholder={t("Phone")}
    />
  </div>

  <button
    type="submit"
    disabled={loading}
    className="w-full bg-black text-white mt-5 py-3  shadow-sm hover:bg-[#ccb898] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
  >
    {loading ? "Processing..." : t("Place Order")}
  </button>

  {unavailableProducts.length > 0 && (
    <div className="mt-4 text-red-500">
      {unavailableProducts.map((storeId, index) => (
        <p key={index}>
          Product from store {storeId} is not available in your selected country.
        </p>
      ))}
    </div>
  )}
</form>

  );
};

const Cashondelivery = ({
  currency,
  onSuccess,
  couponCode,
  onCityChange,
  setSelectedShippingRate,
  onCountryChange,
  totalAmount,
}) => (
  <CashondeliveryForm
    currency={currency}
    onSuccess={onSuccess}
    couponCode={couponCode}
    onCityChange={onCityChange}
    setSelectedShippingRate={setSelectedShippingRate}
    onCountryChange={onCountryChange}
    totalAmount={totalAmount}
  />
);

export default Cashondelivery;