import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from "../../Components/Home/Navbar";
import Loading from "../../Components/Home/Loading";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // Import useTranslation
const Product = ({currency}) => {
  const { t } = useTranslation(); // Initialize translation
  const { categoryId, subcategoryId, subsubcategoryId } = useParams();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);  // Track the current page
  const productsPerPage = 16; // Show 16 products per page
  const token = localStorage.getItem('token');


  const fetchExchangeRate = async (toCurrency) => {
    if (!toCurrency) {
      console.error("Currency is undefined");
      return 1; // Return a default rate (1) if currency is not provided
    }
  
    try {
      const apiUrl = 'https://api.exchangerate-api.com/v4/latest/mad';
      const response = await axios.get(apiUrl);
      const data = response.data;
      const rate = data.rates[toCurrency.toUpperCase()];
      return rate || 1; // Return 1 if the rate is undefined
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      return 1; // Fallback to 1 in case of error
    }
  };

  // Construct the correct API URL
  const constructApiUrl = () => {
    let url = `https://bk.idymorocco.com/api/categories/filter/${categoryId}`;
    if (subcategoryId) {
      url += `/${subcategoryId}`;
      if (subsubcategoryId) {
        url += `/${subsubcategoryId}`;
      }
    }
    return url;
  };

  // Fetch products and display names
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      setError(null);

      try {
        const url = constructApiUrl();
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept": "application/json",
          },
        });

        // Flatten the product list from categories, subcategories, and sub-subcategories
        const fetchedProducts = response.data.flatMap(category =>
          category.sub_category.flatMap(subCategory =>
            subCategory.sub_sub_category.flatMap(subSubCategory =>
              subSubCategory.product || []
            )
          )
        );

        const rate = await fetchExchangeRate(currency); // Fetch the exchange rate

        // Update product prices with the exchange rate
        const updatedProducts = fetchedProducts.map(product => ({
          ...product,
          base_price: product.base_price * rate,
          compare_at_price: product.compare_at_price ? product.compare_at_price * rate : null
        }));

        setProducts(updatedProducts);

        // Logic to set the correct name (category, subcategory, or sub-subcategory)
        if (subcategoryId && subsubcategoryId) {
          const category = response.data.find(cat => cat.id === parseInt(categoryId));
          const subCategory = category?.sub_category.find(subCat => subCat.id === parseInt(subcategoryId));
          const subSubCategory = subCategory?.sub_sub_category.find(subSubCat => subSubCat.id === parseInt(subsubcategoryId));
          setDisplayName(subSubCategory?.name || 'Unknown Sub-Subcategory');
        } else if (subcategoryId) {
          const category = response.data.find(cat => cat.id === parseInt(categoryId));
          const subCategory = category?.sub_category.find(subCat => subCat.id === parseInt(subcategoryId));
          setDisplayName(subCategory?.name || 'Unknown Subcategory');
        } else {
          const category = response.data.find(cat => cat.id === parseInt(categoryId));
          setDisplayName(category?.name || 'Unknown Category');
        }

      } catch (error) {
        console.error('Error fetching products:', error);
        setError('An error occurred while fetching products. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
    
  }, [categoryId, subcategoryId, subsubcategoryId, currency]);

  if (loading) return <Loading />;
  if (error) return <p className="text-red-500">{error}</p>;

  // Calculate total pages based on the number of products
  const totalPages = Math.ceil(products.length / productsPerPage);

  // Get the products for the current page
  const currentProducts = products.slice(
    (currentPage - 1) * productsPerPage,
    currentPage * productsPerPage
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  
  return (
    <>
      <Navbar />
      <div className="w-full lg:col-span-3">
        <div className="text-center p-10">
          <div>
            <h2 className="text-3xl font-hubballi mb-4">{displayName}</h2>
            <p className="font-hubballi">
              {t("Explore our latest arrivals of unique Handmade Moroccan Homeware.All your Interior Decor Inspiration in one place.")}
            </p>
          </div>

          {/* Check if there are products to display */}
          {currentProducts.length > 0 ? (
            <div className="w-full  mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-items-center gap-y-10 gap-x-10 mt-10 mb-5 p-5">
              {currentProducts.map((product) => (
                <Link to={`/product/${product.id}`}>
                  <button 
            className="relative w-full h-72 overflow-hidden bg-cover bg-no-repeat shadow-md"
            onClick={() => window.location.href = `/product/${product.id}`}
          >
            <img
              src={
                product.images.length > 0
                  ? product.images[0].url
                  : `https://via.placeholder.com/300?text=${product.name}`
              }
              className="object-cover w-full h-full transition-transform duration-300 ease-in-out hover:scale-110"
              alt={product.name}
            />
          </button>
                  {/* Product Name */}
          <div className="pt-3 w-full text-center font-hubballi">
            <p className="text-lg font-medium">{product.name}</p>
          </div>

          {/* Product Price */}
          <div className="pt-2 text-center font-hubballi">
            <p className="text-md">
              {product.base_price
                ? product.base_price.toFixed(2)
                : "0.00"}{" "}
              {currency ? currency.toUpperCase() : "MAD"}
              {product.compare_at_price &&
  product.compare_at_price !== product.base_price && (
    <span className="line-through text-gray-500 ml-2">
      {product.compare_at_price.toFixed(2)}
      {currency ? currency.toUpperCase() : "MAD"}
    </span>
  )}
            </p>
          </div>
                </Link>
              ))}
            </div>
          
          ) : (
            <div className="w-full bg-gray-100 mx-auto text-center mt-10 mb-5 p-5">
              <p className="text-gray-700 font-hubballi text-xl">{t("No products available at the moment. Please check back later.")}</p>
            </div>
          )}

          {/* Pagination */}
          <div className="w-full border-gray-200 mt-16">
  <nav className="pagination flex justify-center text-gray-700 -mt-px">
    {/* Previous Button */}
    <button
      onClick={() => handlePageChange(currentPage - 1)}
      disabled={currentPage === 1}
      className={`p-2 mx-1 ${currentPage === 1 ? 'text-gray-400' : ''}`}
    >
      &lt;
    </button>

    {/* Page Numbers */}
    {[...Array(totalPages)].map((_, index) => (
      <button
        key={index}
        onClick={() => handlePageChange(index + 1)}
        className={`px-4 py-2 mx-1 text-sm font-medium ${
          currentPage === index + 1
            ? 'text-black border-b-2 border-black'
            : 'text-gray-500 hover:text-black border-b-2 border-transparent hover:border-gray-300'
        }`}
      >
        {index + 1}
      </button>
    ))}

    {/* Next Button */}
    <button
      onClick={() => handlePageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
      className={`p-2 mx-1 ${currentPage === totalPages ? 'text-gray-400' : ''}`}
    >
      &gt;
    </button>
  </nav>
</div>
        </div>
      </div>
    </>
  );
};

export default Product;
