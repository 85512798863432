import React from 'react';
import Navbar from "../../Components/Home/Navbar";
const FAQ = () => {
    const faqs = [
        {
            question: "Comment protégez-vous mes données personnelles ?",
            answer: "Vos données sont précieusement gardées en sécurité. Nous respectons scrupuleusement la législation en vigueur pour collecter et utiliser vos informations personnelles. Vos données ne seront jamais partagées sans votre consentement explicite."
        },
        {
            question: "Quelle est votre politique de retour ?",
            answer: "Vous disposez de 14 jours à partir de la réception de votre commande pour exercer votre droit de rétractation. Aucun motif n'est requis, et aucune pénalité n'est appliquée, à condition que les articles soient retournés dans leur état et emballage d'origine. Les articles doivent être complets, y compris tous les accessoires et preuves d'achat. Sont exclus les produits soldés, personnalisés, périssables, alimentaires et cosmétiques. Les frais de retour sont à votre charge."
        },
        {
            question: "Que faire si mon article est endommagé ou ne correspond pas à la description ?",
            answer: "Vérifiez l'état de vos produits dès réception. Si un article est endommagé ou ne correspond pas à sa description, vous avez 14 jours pour nous contacter avec des preuves (photos) à l'adresse suivante : contact@idymorocco.com, nous traiterons votre réclamation rapidement pour trouver la meilleure solution."
        },
        {
            question: "Mon paiement est-il sécurisé ?",
            answer: "Absolument. Toutes les transactions sont traitées par des systèmes de paiement hautement sécurisés. Vos données bancaires sont cryptées pour garantir la sécurité de vos paiements."
        },
        {
            question: "Quels sont les délais de livraison ?",
            answer: "Nos délais de livraison varient entre 3 et 7 jours ouvrables. Si votre produit est réalisé sur commande, le délai d’expédition sera indiqué. Vous serez informé du temps de livraison spécifique à chaque commande."
        },
        {
            question: "Qui paie les frais de livraison ?",
            answer: "Les frais de livraison sont à votre charge et dépendent du poids, des dimensions du produit et du pays de destination. Les frais de livraison sont calculés individuellement pour chaque article."
        },
        {
            question: "Puis-je suivre ma commande en direct ?",
            answer: "Oui, vous pouvez suivre l'état de votre commande directement dans votre espace client, sous l'onglet 'Suivi de commande'."
        },
        {
            question: "Quel est le délai de remboursement ?",
            answer: "Une fois que nous avons reçu et vérifié votre retour, nous traiterons votre remboursement immédiatement. Vous recevrez un e-mail de confirmation de notre part, et votre remboursement sera effectué dans les jours suivants."
        }
    ];

    return (
        <><Navbar /><div className="container mx-auto p-20 font-hubballi">
            <h1 className="text-3xl font-bold mb-6 text-center">FAQ</h1>
            <div className="space-y-4">
                {faqs.map((faq, index) => (
                    <div key={index} className="border-b border-gray-200 pb-4">
                        <h2 className="text-xl font-semibold">{faq.question}</h2>
                        <p className="mt-2 text-gray-700">{faq.answer}</p>
                    </div>
                ))}
            </div>
            <div className="mt-6">
                <p className="text-gray-600">
                    Cette FAQ est conçue pour offrir une expérience utilisateur optimale, répondant aux questions essentielles avec clarté et transparence. Pour toute autre question, n'hésitez pas à nous contacter directement.
                </p>
            </div>
        </div></>
    );
};

export default FAQ;
