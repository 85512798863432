import React, { createContext, useState, useEffect } from 'react';

// Create a context for the cart
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [cartItemsCount, setCartItemsCount] = useState(0);

  // Fetch cart items from API or localStorage on mount
  useEffect(() => {
    const fetchCartItems = async () => {
      const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      setCartItems(storedCartItems);
      setCartItemsCount(storedCartItems.length);
    };

    fetchCartItems();
  }, []);

  // Function to add an item to the cart
  const addToCart = (item) => {
    const updatedCartItems = [...cartItems, item];
    setCartItems(updatedCartItems);
    setCartItemsCount(updatedCartItems.length);
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  };

  // Function to remove an item from the cart
  const removeFromCart = (itemId) => {
    const updatedCartItems = cartItems.filter(item => item.id !== itemId);
    setCartItems(updatedCartItems);
    setCartItemsCount(updatedCartItems.length);
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  };

  return (
    <CartContext.Provider value={{ cartItems, cartItemsCount, addToCart, removeFromCart }}>
      {children}
    </CartContext.Provider>
  );
};
