// src/components/AboutUs.js
import React from "react";
import "../../Styles/Aboutuspage.css"; // Adjust the path as necessary
import Navbar from "../../Components/Home/Navbar"; // Adjust the path as necessary
import Product from "../../Assets/Product.png";
import ahwach from "../../Assets/ahwach.png";
import { useTranslation } from "react-i18next"; // Import hook for translation
const AboutUs = () => {
  const { t } = useTranslation(); // Initialize translation hook
  return (
    <div>
  
      <Navbar />
      <div className="aboutus-container">
        <div className="aboutus-section">
          <h2>{t("WHO_WE_ARE")}</h2>
          <p>
          {t("WHO_WE_ARE_DESCRIPTION")}
          </p>
          <img src={Product} alt="WHO WE ARE" />
        </div>
        <div className="aboutus-section">
          <h2>{t("OUR_PHILOSOPHY")}</h2>
          <p>
          {t("OUR_PHILOSOPHY_DESCRIPTION")}
          </p>
          <img
            src={ahwach}
            alt="OUR PHILOSOPHY"
            className="aboutus-image-philosophy"
          />
        </div>
        <div className="aboutus-section">
          <h2>{t("OUR_STORY")}</h2>
          <p>
          {t("OUR_STORY_DESCRIPTION")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
