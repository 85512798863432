import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../Styles/Loginpage.css"; // Adjust the path as necessary
import Navbar from "../../Components/Home/Navbar"; // Adjust the path as necessary
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing eye icons
import { useTranslation } from "react-i18next"; // Import the hook

const LoginPage = () => {
  const { t } = useTranslation(); // Initialize the translation function
  const [email, setEmail] = useState(""); // State for email
  const [password, setPassword] = useState(""); // State for password
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages
  const navigate = useNavigate(); // Get the navigate function for redirecting

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("account_data");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const response = await fetch("https://bk.idymorocco.com/api/login", {
        method: "POST",
        headers: {
           "Content-Type": "application/json",
          "Accept": "application/json",
         },
        body: JSON.stringify({ email, password }),
        
      });

      if (!response.ok) {
        throw new Error(`Failed to log in: ${response.statusText}`);
      }

      const data = await response.json();

      localStorage.setItem("token", data.access_token);
      localStorage.setItem("account_data", JSON.stringify(data.Account_data[0]));

      navigate("/");
    } catch (error) {
      console.error("Login failed", error);
      setErrorMessage(t("ERROR_MESSAGE")); // Use translated error message
    }
  };

  return (
    <div>
      <Navbar />
      <div className="login-container">
        <h1 className="login-title">{t("LOGIN_TITLE")}</h1>
        <p className="login-subtitle">{t("LOGIN_SUBTITLE")}</p>
        <form className="login-form" onSubmit={handleLogin}>
          <input
            type="email"
            placeholder={t("EMAIL_PLACEHOLDER")}
            className="login-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder={t("PASSWORD_PLACEHOLDER")}
              className="login-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="password-toggle"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
            {/* <a href="#forgot-password" className="forgot-password">
              {t("FORGOT_PASSWORD")}
            </a> */}
          </div>
          {errorMessage && <p className="font-hubballi error-message text-red-600 mt-4">{errorMessage}</p>}
          <button type="submit" className="login-button">
            {t("LOGIN_BUTTON")}
          </button>
        </form>
        <p className="create-account">
          {t("CREATE_ACCOUNT")} <Link to="/register">{t("CREATE_ACCOUNT_LINK")}</Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
