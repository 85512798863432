import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";
const SearchModal = ({ isOpen, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const currency = "MAD"; // Example currency, you can replace it with your logic
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  // Fetch all products once when the modal is opened
  useEffect(() => {
    if (isOpen) {
      fetchAllProducts();
    }
  }, [isOpen]);

  const fetchAllProducts = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://bk.idymorocco.com/api/products');
      const products = response.data.data;

      if (Array.isArray(products)) {
        setAllProducts(products);
      } else {
        console.error("Products are not an array:", products);
        setAllProducts([]);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setAllProducts([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle input change and filter products
  const handleInputChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query.trim() !== '') {
      // Filter products based on the first letter
      const filtered = allProducts.filter((product) =>
        product.name.toLowerCase().startsWith(query) // Change here to startsWith
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center font-hubballi items-center z-50 ${i18n.language === 'ar' ? 'text-right' : 'text-left'}`}
    dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
  <div className="bg-white p-4 md:p-6 shadow-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto ">
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-lg md:text-xl font-semibold">{t("Search")}</h2>
      <button onClick={onClose} className="text-gray-600 hover:text-gray-900">
        <i className="fas fa-times"></i>
      </button>
    </div>

    <input
      type="text"
      className="w-full p-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
      placeholder={t("Search")}
      value={searchQuery}
      onChange={handleInputChange}
    />

    {isLoading ? (
      <p className="mt-4 text-center">{t("Loading")}</p>
    ) : (
      <div className="mt-4">
        {searchQuery.length > 0 && filteredProducts.length > 0 ? (
          <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {filteredProducts.map((product) => (
              <li key={product.id} className="flex flex-col items-center">
                <div
                  className="relative w-full max-w-xs overflow-hidden bg-cover bg-no-repeat"
                  onClick={() => window.location.href = `/product/${product.id}`}
                >
                  <img
                    src={
                      product.images && product.images.length > 0
                        ? product.images[0].url
                        : `https://via.placeholder.com/300?text=${product.name}`
                    }
                    alt={product.title}
                    className="w-full transition duration-300 ease-in-out hover:scale-105"
                  />
                </div>
                <div className="pt-3 w-full text-center font-hubballi">
                  <p className="truncate">{product.name}</p>
                  <p className="mt-1">
                    {product.base_price.toFixed(2)} {currency ? currency.toUpperCase() : 'MAD'}
                    {product.compare_at_price &&
  product.compare_at_price !== product.base_price && (
    <span className="line-through text-gray-500 ml-2">
      {product.compare_at_price.toFixed(2)}
      {currency ? currency.toUpperCase() : "MAD"}
    </span>
  )}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        ) : searchQuery.length > 0 ? (
          <p className="mt-4 text-center text-gray-600">{t("No products found.")}</p>
        ) : (
          <p className="mt-4 text-center text-gray-600">{t("Please search for a product that exists.")}</p>
        )}
      </div>
    )}
  </div>
</div>

  );
};

export default SearchModal;
